import { useParams } from "react-router-dom"
import {
  WebhookSubscriptionFragment,
  namedOperations,
  useDeleteWebhookSubscriptionMutation,
  useWebhookSubscriptionsQuery,
} from "@earnnest-e2-frontend/platform-api/src/graphql"
import CreateWebhookSubscriptionForm from "../forms/CreateWebhookSubscriptionForm"
import { useState } from "react"
import { Button, Table, Space, Text, Loader } from "@mantine/core"
import { notifications } from "@mantine/notifications"

export default function WebhookSubscriptionsScreen() {
  const params = useParams()

  const [formVisible, setFormVisible] = useState(false)

  const webhooksQuery = useWebhookSubscriptionsQuery({
    variables: {
      integrationId: params.integrationId,
    },
  })

  return (
    <>
      <Table
        sx={{
          "tr:not(:hover) button": {
            opacity: 0,
          },
        }}>
        <tbody>
          {webhooksQuery.data?.webhookSubscriptions ? (
            webhooksQuery.data?.webhookSubscriptions?.map(
              (webhookSubscription) => (
                <WebhookSubscriptionRow
                  key={webhookSubscription.id}
                  integrationId={params.integrationId}
                  webhookSubscription={webhookSubscription}
                />
              ),
            )
          ) : webhooksQuery.loading ? (
            <Loader size="sm" />
          ) : webhooksQuery.error ? (
            <Text color="red">
              {webhooksQuery.error.message ||
                "Could not load webhook subscriptions."}
            </Text>
          ) : (
            <Text color="dimmed">No webhooks subscriptions connected yet.</Text>
          )}
        </tbody>
      </Table>
      <Space h="xl" />
      {formVisible ? (
        <CreateWebhookSubscriptionForm
          integrationId={params.integrationId}
          onCancel={() => setFormVisible(false)}
          onSubmitSuccess={() => setFormVisible(false)}
        />
      ) : (
        <Button variant="white" onClick={() => setFormVisible(true)}>
          Add endpoint
        </Button>
      )}
    </>
  )
}

function WebhookSubscriptionRow({
  integrationId,
  webhookSubscription,
}: {
  integrationId: string
  webhookSubscription: WebhookSubscriptionFragment
}) {
  const [
    deleteWebhookSubscription,
    deleteWebhookSubscriptionState,
  ] = useDeleteWebhookSubscriptionMutation()

  return (
    <tr key={webhookSubscription.id}>
      <td>{webhookSubscription.url}</td>
      <td align="right">
        <Button
          size="sm"
          variant="white"
          loading={deleteWebhookSubscriptionState.loading}
          onClick={async () => {
            try {
              await deleteWebhookSubscription({
                variables: {
                  integrationId,
                  subscriptionId: webhookSubscription.id,
                },
                refetchQueries: [namedOperations.Query.WebhookSubscriptions],
              })
            } catch (error) {
              notifications.show({
                color: "red",
                title: "Error",
                message: error.message,
              })
            }
          }}>
          Remove
        </Button>
      </td>
    </tr>
  )
}
