import React, { forwardRef, Ref } from "react"
import { StyleProp, View, ViewStyle } from "react-native"
import { useTheme } from "./Theme"
import { BoxProps } from "./types"

// Thin layer on top of View to support consistent layout and color from Theme

export default forwardRef((props: BoxProps, ref: Ref<View>) => {
  const {
    w,
    h,
    m,
    mt,
    ml,
    mr,
    mb,
    mx,
    my,
    p,
    pt,
    pl,
    pr,
    pb,
    px,
    py,
    r,
    bg,
    style,
    shadow,
    children,
    ...rest
  } = props

  const { getColor, getShadow } = useTheme()

  let layoutStyle: StyleProp<ViewStyle> = {}
  if (w) layoutStyle.width = w
  if (h) layoutStyle.height = h
  if (m) layoutStyle.margin = m
  if (mt) layoutStyle.marginTop = mt
  if (ml) layoutStyle.marginLeft = ml
  if (mr) layoutStyle.marginRight = mr
  if (mb) layoutStyle.marginBottom = mb
  if (mx) layoutStyle.marginHorizontal = mx
  if (my) layoutStyle.marginVertical = my
  if (p) layoutStyle.padding = p
  if (pt) layoutStyle.paddingTop = pt
  if (pl) layoutStyle.paddingLeft = pl
  if (pr) layoutStyle.paddingRight = pr
  if (pb) layoutStyle.paddingBottom = pb
  if (px) layoutStyle.paddingHorizontal = px
  if (py) layoutStyle.paddingVertical = py
  if (r) layoutStyle.borderRadius = r

  let colorStyle: StyleProp<ViewStyle> = {}
  if (bg) {
    colorStyle = {
      backgroundColor: getColor(bg),
    }
  }

  let shadowStyle: StyleProp<ViewStyle> | undefined
  if (shadow) {
    shadowStyle = getShadow(shadow)
  }

  return (
    <View
      {...rest}
      ref={ref}
      style={[
        // @ts-ignore
        zIndexFixStyle,
        layoutStyle,
        colorStyle,
        shadowStyle,
        style as StyleProp<ViewStyle>,
      ]}>
      {children}
    </View>
  )
})

// By default, react-native web sets zIndex: 0 which causes problems for Tooltips
// or any inline element that wants to be positioned on top of parent siblings.
// This is an unfortunate solution but works as long as we use Box instead of View.
// https://github.com/necolas/react-native-web/issues/1065#issuecomment-468633908
const zIndexFixStyle = { zIndex: "auto" }
