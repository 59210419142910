import React from "react"
import { Animated, Easing, Platform } from "react-native"
import Svg, { Circle, Path } from "react-native-svg"
import { useTheme } from "../UI"

export default function Spinner({ size = 32, speed = 500, color = "green" }) {
  const { getColor } = useTheme()
  const rotation = React.useRef(new Animated.Value(0))

  React.useEffect(() => {
    const animation = Animated.loop(
      Animated.timing(rotation.current, {
        toValue: 1,
        duration: speed,
        easing: Easing.linear,
        useNativeDriver: Platform.OS !== "web",
      }),
    )
    animation.start()
    return () => animation.stop()
  }, [speed])

  return (
    <Animated.View
      style={{
        width: size,
        height: size,
        transform: [
          {
            rotate: rotation.current.interpolate({
              inputRange: [0, 1],
              outputRange: ["0deg", "360deg"],
            }),
          },
        ],
      }}>
      <Svg width={size} height={size} viewBox="0 0 32 32" fill="none">
        <Circle
          cx="15.9998"
          cy="16"
          r="11.9766"
          strokeOpacity="0.5"
          stroke={getColor("aqua")}
          strokeWidth={size > 48 ? 3 : 4}
        />
        <Path
          d="M16 4.02344C11.1908 4.02344 7.04374 6.85793 5.13818 10.9474"
          stroke={getColor(color)}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={size > 48 ? 3 : 4}
        />
      </Svg>
    </Animated.View>
  )
}
