import React from "react"
import Wordmark from "./Wordmark"
import { Box, Text } from "../UI"
import { useTheme } from "../UI/Theme"

export default function PoweredBy({ color }) {
  const { getColor } = useTheme()
  return (
    <a
      href="https://earnnest.com/?utm_source=epro&utm_medium=click-thru&utm_campaign=powered-by-earnnest"
      rel="noopener noreferrer"
      target="_blank">
      <Box
        style={{
          flexDirection: "row",
          alignItems: "center",
          flexWrap: "wrap",
        }}>
        <Text
          color={color}
          type="disclaimer"
          numberOfLines={1}
          style={{ marginVertical: 4 }}>
          Powered by
        </Text>
        <Box w={12} />
        <Wordmark color={getColor(color)} style={{ height: 12 }} />
      </Box>
    </a>
  )
}
