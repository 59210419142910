import Button from "../Button"
import { Box, Text } from "../UI"
import React from "react"

interface ErrorPageProps {
  errorCode?: string | null
  errorMessage?: string | null
  onLogout?: Function
}

export default function ErrorScreen({
  errorCode,
  errorMessage,
  onLogout,
}: ErrorPageProps) {
  return (
    <Box style={{ flex: 1, alignItems: "center", justifyContent: "center" }}>
      <Text type="heading3" color="contrast100">
        Error {errorCode}
      </Text>
      <Box h={12} />
      <Text type="baseText" color="contrast100">
        {errorMessage || "An unexpected error occurred."}
      </Text>
      {onLogout ? (
        <Box pt={16} pb={24}>
          <Button title="Logout" onPress={() => onLogout()} />
        </Box>
      ) : null}
    </Box>
  )
}
