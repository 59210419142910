import { Box, Group, Text, Tooltip } from "@mantine/core"
import { ReactNode } from "react"

export function MetaRow({
  label,
  children,
  tooltipText,
}: {
  label: string
  children?: ReactNode
  tooltipText?: string
}) {
  return (
    <Group noWrap align="baseline" my="xs">
      {tooltipText ? (
        <Tooltip multiline label={tooltipText} width={300}>
          <Text color="dimmed" weight={500} sx={{ width: 160, flexShrink: 0 }}>
            {label}
          </Text>
        </Tooltip>
      ) : (
        <Text color="dimmed" weight={500} sx={{ width: 160, flexShrink: 0 }}>
          {label}
        </Text>
      )}
      <Box w="100%">
        {typeof children === "string" ? <Text>{children}</Text> : children}
      </Box>
    </Group>
  )
}
