import React, { useEffect } from "react"
import { Keyboard, StyleSheet } from "react-native"
import Spinner from "../Spinner"
import { Box, Text, Color } from "../UI"

interface LoadingOverlayProps {
  title?: string
  bg?: Color
  opacity?: number
}

export default function LoadingOverlay({
  title,
  bg = "contrast05",
}: LoadingOverlayProps) {
  useEffect(() => {
    Keyboard.dismiss()
  }, [])

  return (
    <Box
      bg={bg}
      style={[
        StyleSheet.absoluteFill,
        {
          zIndex: 9001,
          alignItems: "center",
          justifyContent: "center",
        },
      ]}>
      <Box h={128} style={{ alignItems: "center", flexDirection: "column" }}>
        <Spinner color="green" size={64} />
        {title ? (
          <Box mt={16}>
            <Text type="heading2" color="contrast100" center>
              {title}
            </Text>
          </Box>
        ) : null}
      </Box>
    </Box>
  )
}
