import React from "react"
import { StyleProp, View, ViewStyle } from "react-native"
import { Color, useTheme } from "../UI"
import { ShadowLevel } from "../UI/types"

interface CircularIconProps {
  Icon: any
  color?: Color
  backgroundColor?: Color
  size: number
  iconRatio?: number
  style?: StyleProp<ViewStyle>
  iconStyle?: StyleProp<ViewStyle>
  kind?: "border" | "fill"
  shadow?: ShadowLevel
}

export default function CircularIcon({
  Icon,
  size = 24,
  iconRatio = 0.5,
  color = "contrast0",
  backgroundColor = "contrast95",
  style,
  iconStyle,
  kind = "fill",
  shadow,
  ...rest
}: CircularIconProps) {
  const { getColor, getShadow } = useTheme()
  const actualIconRatio = [0, iconRatio, 1].sort((a, b) => a - b)[1]
  let shadowStyle: StyleProp<ViewStyle> | undefined
  if (shadow) {
    shadowStyle = getShadow(shadow)
  }
  return (
    <View
      {...rest}
      style={[
        {
          width: size,
          height: size,
          borderRadius: size,
          alignItems: "center",
          justifyContent: "center",
          backgroundColor:
            kind === "border" ? "none" : getColor(backgroundColor),
          borderWidth: kind === "border" ? 1 : 0,
          borderColor: kind === "border" ? getColor(backgroundColor) : "none",
        },
        shadowStyle,
        style,
      ]}>
      <Icon
        width={Math.round(size * actualIconRatio)}
        height={Math.round(size * actualIconRatio)}
        style={[
          {
            color:
              kind === "border" ? getColor(backgroundColor) : getColor(color),
          },
          iconStyle,
        ]}
      />
    </View>
  )
}
