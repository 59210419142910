import * as React from "react"
import Svg, { Path } from "react-native-svg"

function CloseIcon(props) {
  return (
    <Svg width={14} height={14} viewBox="0 0 14 14" fill="none" {...props}>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.06 1.06a1.5 1.5 0 000 2.122l3.89 3.889-3.89 3.89a1.5 1.5 0 102.122 2.12L7.07 9.193l3.89 3.89a1.5 1.5 0 102.12-2.122L9.193 7.071l3.89-3.889a1.5 1.5 0 10-2.122-2.121L7.07 4.95 3.182 1.06a1.5 1.5 0 00-2.121 0z"
        fill="currentColor"
      />
    </Svg>
  )
}

export default CloseIcon
