import { useApolloClient } from "@apollo/client"
import {
  WebhookAttemptFragment,
  WebhookAttemptsDocument,
  WebhookAttemptsQuery,
  WebhookAttemptsQueryResult,
  useRetryWebhookAttemptMutation,
  useWebhookAttemptsQuery,
} from "@earnnest-e2-frontend/platform-api/src/graphql"
import { WebhookAttemptStatusBadge } from "@earnnest-e2-frontend/platform-ui/src/mantine/StatusBadges"
import { Box, Button, Group, Loader, Table, Text } from "@mantine/core"
import { notifications } from "@mantine/notifications"
import { useState } from "react"
import { useParams } from "react-router-dom"
import { v4 as uuid } from "uuid"
import dayjs from "dayjs"

export default function WebhookAttemptsScreen() {
  const params = useParams()

  const query = useWebhookAttemptsQuery({
    variables: {
      integrationId: params.integrationId,
      limit: 50,
    },
  })

  return <WebhookAttemptsTable query={query} />
}

function WebhookAttemptsTable({
  query,
}: {
  query: WebhookAttemptsQueryResult
}) {
  const apolloClient = useApolloClient()

  const [paginating, setPaginating] = useState(false)

  const items = query.data?.webhookAttempts?.entries
  const pageInfo = query.data?.webhookAttempts?.pageInfo

  const [
    retryWebhookAttempt,
    retryWebhookAttemptState,
  ] = useRetryWebhookAttemptMutation()

  async function handleRetry(webhookAttempt: WebhookAttemptFragment) {
    try {
      await retryWebhookAttempt({
        variables: {
          attemptId: webhookAttempt.id,
        },
      })
      const data = apolloClient.readQuery<WebhookAttemptsQuery>({
        query: WebhookAttemptsDocument,
        variables: query.variables,
      })
      apolloClient.writeQuery<WebhookAttemptsQuery>({
        query: WebhookAttemptsDocument,
        variables: query.variables,
        data: {
          webhookAttempts: {
            ...data?.webhookAttempts,
            entries: [
              {
                ...webhookAttempt,
                id: uuid(),
                resultCode: 0, // displays as Queued
                createdAt: new Date().toISOString(),
              },
              ...(data?.webhookAttempts?.entries || []),
            ],
          },
        },
      })
    } catch (error) {
      notifications.show({
        color: "red",
        title: "Error",
        message: error.message,
      })
    }
  }

  return (
    <>
      {items?.length ? (
        <Table>
          <thead>
            <tr>
              <th>Status</th>
              <th>Transaction Template ID</th>
              <th>URL</th>
              <th>Date</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {items.map((webhookAttempt) => (
              <tr key={webhookAttempt.id}>
                <td>
                  <WebhookAttemptStatusBadge webhookAttempt={webhookAttempt} />
                </td>
                <td>{webhookAttempt.transactionTemplate?.id}</td>
                <td>{webhookAttempt.url}</td>
                <td>
                  {dayjs(webhookAttempt.createdAt).format(
                    "MMM D, YYYY [at] H:mm A",
                  )}
                </td>
                <td style={{ width: 120, textAlign: "right" }}>
                  {webhookAttempt.resultCode >= 400 ? (
                    <Button
                      size="sm"
                      variant="white"
                      sx={{
                        "tr:not(:hover) > td > &": {
                          opacity: 0,
                        },
                      }}
                      loading={retryWebhookAttemptState.loading}
                      onClick={() => handleRetry(webhookAttempt)}>
                      Retry
                    </Button>
                  ) : null}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      ) : null}
      {items?.length ? (
        <Group py="md" position="apart">
          {pageInfo?.hasMoreEntries ? (
            <Button
              variant="white"
              loading={query.loading || paginating}
              onClick={async () => {
                try {
                  setPaginating(true)
                  await query.fetchMore({
                    variables: {
                      afterCursor: pageInfo?.afterCursor || "",
                    },
                    updateQuery: (prev: any, { fetchMoreResult }) => {
                      if (!fetchMoreResult) return prev
                      return {
                        ...prev,
                        transactionTemplates: {
                          pageInfo: fetchMoreResult?.webhookAttempts?.pageInfo,
                          entries: [
                            ...prev.transactionTemplates?.entries,
                            ...(fetchMoreResult?.webhookAttempts?.entries ||
                              []),
                          ],
                        },
                      }
                    },
                  })
                } catch (error) {
                  notifications.show({
                    color: "red",
                    title: "Error",
                    message: error.message,
                  })
                } finally {
                  setPaginating(false)
                }
              }}>
              Load more
            </Button>
          ) : null}
          <Box />
          <Text color="dimmed" size="sm">
            {pageInfo?.totalCount} total
          </Text>
        </Group>
      ) : query.loading ? (
        <Group spacing="sm">
          <Loader size="sm" />
          <Text>Loading...</Text>
        </Group>
      ) : query.error ? (
        <Text color="red">{query.error.message}</Text>
      ) : (
        <Text color="dimmed">Nothing here yet.</Text>
      )}
    </>
  )
}
