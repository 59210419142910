import { useEffect, useState } from "react"

export default function useImageSizeConstraints(
  src: string = "",
  maxSize: number,
) {
  const [width, setWidth] = useState(0)
  const [height, setHeight] = useState(0)
  const [ready, setReady] = useState(false)

  useEffect(() => {
    if (src) {
      let img = new Image()
      img.onload = () => {
        if (img.naturalHeight > img.naturalWidth) {
          setHeight(maxSize)
          setWidth((img.naturalWidth / img.naturalHeight) * maxSize)
        } else {
          setWidth(maxSize)
          setHeight((img.naturalHeight / img.naturalWidth) * maxSize)
        }
        setReady(true)
      }
      img.onerror = () => {
        setReady(true)
      }
      img.src = src
    } else {
      setReady(true)
    }
  }, [src, maxSize])

  return {
    width,
    height,
    ready,
  }
}
