import * as React from "react"
import Svg, { Path } from "react-native-svg"

function LockIcon(props) {
  return (
    <Svg width={14} height={19} viewBox="0 0 17 22" fill="none" {...props}>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.5 6.286C2.5 2.814 5.186 0 8.5 0s6 2.814 6 6.286V9h1a1 1 0 011 1v11a1 1 0 01-1 1h-14a1 1 0 01-1-1V10a1 1 0 011-1h1V6.286zm9 0V9h-6V6.286c0-1.736 1.343-3.143 3-3.143s3 1.407 3 3.143zm0 7.714a3.001 3.001 0 01-2 2.83V19a1 1 0 11-2 0v-2.17a3.001 3.001 0 114-2.83z"
        fill="currentColor"
      />
    </Svg>
  )
}

export default LockIcon
