import {
  namedOperations,
  useCreateWebhookSubscriptionMutation,
} from "@earnnest-e2-frontend/platform-api/src/graphql"
import { Button, Group, Paper, Text, TextInput, Title } from "@mantine/core"
import { useForm, yupResolver } from "@mantine/form"
import { notifications } from "@mantine/notifications"
import * as yup from "yup"

export default function CreateWebhookSubscriptionForm({
  integrationId,
  onCancel,
  onSubmitSuccess,
}: {
  integrationId: string
  onCancel: () => void
  onSubmitSuccess: () => void
}) {
  const [createWebhookSubscription] = useCreateWebhookSubscriptionMutation()

  const form = useForm({
    initialValues: {
      url: "",
      secret: "",
    },
    validate: yupResolver(
      yup.object({
        url: yup.string().url("Invalid url").required("Required"),
        secret: yup.string().required("Required"),
      }),
    ),
  })

  return (
    <form
      onSubmit={form.onSubmit(async (values) => {
        try {
          await createWebhookSubscription({
            variables: {
              integrationId,
              url: values.url,
              secret: values.secret,
            },
            refetchQueries: [namedOperations.Query.WebhookSubscriptions],
          })
          notifications.show({
            color: "green",
            title: "Success",
            message: "Webhook subscription created",
          })
          onSubmitSuccess()
        } catch (error) {
          notifications.show({
            color: "red",
            title: "Error",
            message: error.message,
          })
        }
      })}>
      <Paper p="lg" bg="dark.6">
        <Title size="h2" order={3}>
          Listen to Earnnest events
        </Title>
        <Text>
          Add a new webhook subscrption to receive live events from Earnnest
        </Text>
        <Group mt="md" mb="xl" align="baseline" noWrap>
          <TextInput
            w="100%"
            label="URL"
            description="Your endpoint to receive requests from our webhook"
            inputWrapperOrder={["label", "input", "description", "error"]}
            {...form.getInputProps("url")}
          />
          <TextInput
            w="100%"
            label="Secret"
            description="We will pass this secret along with the webhook event to verify the request"
            inputWrapperOrder={["label", "input", "description", "error"]}
            {...form.getInputProps("secret")}
          />
        </Group>
        <Group spacing="xs" align="center" position="right">
          <Button type="button" variant="white" onClick={() => onCancel()}>
            Cancel
          </Button>
          <Button type="submit" color="green">
            Add endpoint
          </Button>
        </Group>
      </Paper>
    </form>
  )
}
