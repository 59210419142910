import * as React from "react"
import Svg, { Path } from "react-native-svg"

function InfoIcon(props) {
  return (
    <Svg width={4} height={14} viewBox="0 0 4 14" fill="none" {...props}>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 5a2 2 0 012 2v4.667a2 2 0 11-4 0V7a2 2 0 012-2zm0-.667a2 2 0 110-4 2 2 0 010 4z"
        fill="currentColor"
      />
    </Svg>
  )
}

export default InfoIcon
