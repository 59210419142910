import React from "react"
import { TouchableOpacity, TouchableOpacityProps, View } from "react-native"
import { useTheme } from "../UI"

interface IconButtonProps extends TouchableOpacityProps {
  Icon: any
  color?: string
  backgroundColor?: string
  size?: number
  disabled?: boolean
  onPress?: any
  transparent?: boolean
}

export default function IconButton({
  Icon,
  color = "purple50",
  backgroundColor = "purple75",
  size = 24,
  disabled,
  onPress,
  transparent,
  ...rest
}: IconButtonProps) {
  const { getColor } = useTheme()
  const hitSlop = Math.max(0, (44 - size) / 2) // touch target should be at least 44
  return (
    <TouchableOpacity
      {...rest}
      disabled={disabled || !onPress}
      onPress={onPress}
      hitSlop={{
        top: hitSlop,
        left: hitSlop,
        right: hitSlop,
        bottom: hitSlop,
      }}
      activeOpacity={0.8}>
      <View
        style={{
          width: size,
          height: size,
          borderRadius: size,
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: transparent
            ? "transparent"
            : getColor(backgroundColor),
          // @ts-ignore: No overload error
          cursor: "pointer",
        }}>
        <Icon
          width={Math.round(size / 1.75)}
          height={Math.round(size / 1.75)}
          color={getColor(color)}
        />
      </View>
    </TouchableOpacity>
  )
}
