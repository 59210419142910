import {
  Box,
  Button,
  Drawer,
  MantineProvider,
  ScrollArea,
  Space,
  Text,
  useMantineTheme,
} from "@mantine/core"
import { useMediaQuery } from "@mantine/hooks"
import { ReactNode } from "react"
import { LightEarnnestTheme } from "./theme"
import { openConfirmModal } from "@mantine/modals"

interface PanelProps {
  opened: boolean
  onBack?: () => void
  onClose?: () => void
  children?: ReactNode
  confirmClose?: boolean
  closeOnClickOutside?: boolean
  position?: "left" | "right"
  overlay?: boolean
}

export function Panel({
  children,
  opened,
  onBack,
  onClose,
  confirmClose,
  closeOnClickOutside = true,
  position = "right",
  overlay = true,
}: PanelProps) {
  const theme = useMantineTheme()
  const isWidescreen = useMediaQuery(`(min-width: ${theme.breakpoints.lg})`)

  return (
    <MantineProvider theme={LightEarnnestTheme}>
      <Drawer.Root
        opened={opened}
        onClose={onClose}
        position={position}
        size={isWidescreen ? "lg" : "100%"}
        closeOnClickOutside={closeOnClickOutside}
        scrollAreaComponent={ScrollArea}>
        {overlay ? <Drawer.Overlay /> : null}
        <Drawer.Content>
          <Drawer.Header bg="gray.0">
            {onBack ? (
              <Button variant="subtle" onClick={onBack}>
                Back
              </Button>
            ) : null}
            <Space />
            {onClose ? (
              <Button
                variant="subtle"
                onClick={() => {
                  if (confirmClose) {
                    openConfirmModal({
                      styles: {
                        overlay: {
                          zIndex: 202,
                        },
                      },
                      title: "Are you sure?",
                      children: (
                        <Text size="sm">
                          Any information you have filled out will be lost.
                        </Text>
                      ),
                      labels: { confirm: "Confirm", cancel: "Cancel" },
                      onConfirm: () => onClose(),
                    })
                  } else {
                    onClose()
                  }
                }}>
                Close
              </Button>
            ) : null}
          </Drawer.Header>
          <Drawer.Body px="xl" pb={160}>
            {children}
          </Drawer.Body>
        </Drawer.Content>
      </Drawer.Root>
    </MantineProvider>
  )
}

export function PanelFooter({ children }: { children: ReactNode }) {
  const theme = useMantineTheme()
  return (
    <Box
      sx={{
        zIndex: 1,
        position: "fixed",
        left: 0,
        right: 0,
        bottom: 0,
        padding: `${theme.spacing.md} ${theme.spacing.xl}`,
        display: "flex",
        flexDirection: "column",
        backgroundColor: theme.fn.themeColor("gray.0"),
        boxShadow: `0px -2px 20px rgba(41, 40, 58, 0.1)`,
      }}>
      {children}
    </Box>
  )
}

Panel.Footer = PanelFooter
