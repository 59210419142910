import React, { createContext, useContext } from "react"
import {
  Dimensions,
  PixelRatio,
  Platform,
  StyleProp,
  StyleSheet,
  TextStyle,
} from "react-native"
// Move to GlobalStyles module with platform splitting
import "../assets/css/global.css"
import { Shadow, ShadowLevel, Theme } from "./types"
import tinycolor from "tinycolor2"

export const theme: Theme = {
  colors: {
    purple: "#29283A",
    purple95: "#343344",
    purple90: "#3E3E4E",
    purple75: "#5F5E6B",
    purple50: "#9592A7",
    purple20: "#D4D4D8",
    purple10: "#EAE9EB",
    purple02: "#BAB7CE",
    gray: "#F9F9F9",
    white: "#FFFFFF",
    blue: "#41A0F9",
    red: "#ED5828",
    red15: "#C94B22",
    red20: "#F5A085",
    red75: "#EB7B57",
    green: "#30C97F",
    green15: "#29AB6C",
    green20: "#79DBAC",
    aqua: "#24A494",
    yellow: "#DBB83D",
    yellow75: "#E4CA6E",
  },
  shadows: {
    level1: {
      // web
      boxShadow: "0px 0px 3px, 0px 2px 8px",
      // android
      elevation: 1,
      // ios
      shadowOffset: { width: 0, height: 2 },
      shadowRadius: 8,
    },
    level2: {
      // web
      boxShadow: "1px 1px 4px, 0px 8px 12px",
      // android
      elevation: 2,
      // ios
      shadowOffset: { width: 0, height: 8 },
      shadowRadius: 12,
    },
    level3: {
      // web
      boxShadow: "1px 1px 4px, 0px 12px 16px",
      // android
      elevation: 3,
      // ios
      shadowOffset: { width: 0, height: 12 },
      shadowRadius: 16,
    },
  },
  schemes: {
    light: {
      contrast100: "purple",
      contrast95: "purple95",
      contrast90: "purple90",
      contrast75: "purple75",
      contrast50: "purple50",
      contrast20: "purple20",
      contrast10: "purple10",
      contrast05: "gray",
      contrast0: "white",
      foreground: "white",
      background: "gray",
    },
    dark: {
      contrast100: "white",
      contrast95: "gray",
      contrast90: "purple10",
      contrast75: "purple20",
      contrast50: "purple50",
      contrast20: "purple75",
      contrast10: "purple90",
      contrast05: "purple95",
      contrast0: "purple",
      foreground: "purple95",
      background: "purple",
    },
  },
  typography: StyleSheet.create({
    heading1: {
      fontFamily: "Unna",
      fontSize: responsive(48),
      lineHeight: responsive(48),
      letterSpacing: responsive(48 * -0.02),
    },
    heading2: {
      fontFamily: "Unna",
      fontSize: responsive(32),
      lineHeight: responsive(32 * 1.2),
      letterSpacing: responsive(32 * -0.02),
    },
    heading3: {
      fontFamily: "UbuntuMedium",
      fontStyle: "normal",
      fontWeight: "500",
      fontSize: responsive(24),
      lineHeight: responsive(24 * 1.2),
    },
    heading4: {
      fontFamily: "UbuntuMedium",
      fontStyle: "normal",
      fontWeight: "500",
      fontSize: responsive(20),
      lineHeight: responsive(20 * 1.4),
    },
    heading5: {
      fontFamily: "UbuntuMedium",
      fontStyle: "normal",
      fontWeight: "500",
      fontSize: responsive(16),
      lineHeight: responsive(16 * 1.2),
    },
    heading6: {
      fontFamily: "UbuntuMedium",
      fontStyle: "normal",
      fontWeight: "500",
      fontSize: responsive(14),
      lineHeight: responsive(14 * 1.2),
    },
    heading7: {
      fontFamily: "UbuntuMedium",
      fontStyle: "normal",
      fontWeight: "500",
      fontSize: responsive(12),
      lineHeight: responsive(12 * 1.2),
    },
    baseText: {
      fontFamily: "Ubuntu",
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: responsive(16),
      lineHeight: responsive(16 * 1.4),
    },
    smallText: {
      fontFamily: "Ubuntu",
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: responsive(12),
      lineHeight: responsive(12 * 1.4),
    },
    smallTextItalic: {
      fontFamily: "UbuntuItalic",
      fontStyle: "italic",
      fontWeight: "normal",
      fontSize: responsive(12),
      lineHeight: responsive(12 * 1.4),
    },
    label: {
      fontFamily: "UbuntuMedium",
      fontSize: responsive(12),
      lineHeight: responsive(12 * 1.4),
      letterSpacing: responsive(12 * 0.02),
    },
    disclaimer: {
      fontFamily: "Ubuntu",
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: responsive(10),
      lineHeight: responsive(10),
    },
  }),
}

type Scheme = keyof Theme["schemes"]

const defaultScheme = "light"

// scale down as screen gets smaller than 375
export function responsive(n: number): number {
  const scale = Math.min(1, Dimensions.get("window").width / 375)
  return PixelRatio.roundToNearestPixel(n * scale)
}

function getColor(name: string, scheme?: Scheme): string {
  const activeScheme = scheme || defaultScheme
  return (
    // First check in active color scheme (using value as key in theme colors)
    theme.colors[theme.schemes[activeScheme][name]] ||
    // Next check in theme colors (by key)
    theme.colors[name]
  )
}

function getTypography(name: string): StyleProp<TextStyle> {
  return theme.typography[name]
}

function getShadow(level: ShadowLevel, scheme?: Scheme): Shadow {
  const activeScheme = scheme || defaultScheme
  const shadow = theme.shadows[level]
  if (Platform.OS === "android") {
    return { elevation: shadow.elevation }
  }
  const shadowColor = getColor("purple")
  const shadowOpacity = activeScheme === "dark" ? 0.4 : 0.15
  if (Platform.OS === "ios") {
    return {
      shadowOffset: shadow.shadowOffset,
      shadowRadius: shadow.shadowRadius,
      shadowColor: shadowColor,
      shadowOpacity: shadowOpacity,
    }
  }
  const shadowRgba = tinycolor(shadowColor).setAlpha(shadowOpacity).toRgbString() // prettier-ignore
  const boxShadow = shadow.boxShadow?.split(",").map((x) => `${x} ${shadowRgba}`).join(",") // prettier-ignore
  return { boxShadow }
}

export const ThemeContext = createContext({
  activeScheme: "light",
  getColor: getColor,
  getTypography: getTypography,
  getShadow: getShadow,
})

export function ThemeProvider({
  activeScheme = "light",
  children,
}: {
  activeScheme: Scheme
  children: any
}) {
  return (
    <ThemeContext.Provider
      value={{
        activeScheme,
        getTypography,
        getShadow: (level: ShadowLevel) => getShadow(level, activeScheme),
        getColor: (name: string) => getColor(name, activeScheme),
      }}>
      {children}
    </ThemeContext.Provider>
  )
}

export function LightColorSchemeProvider({ children }) {
  return <ThemeProvider activeScheme="light">{children}</ThemeProvider>
}

export function DarkColorSchemeProvider({ children }) {
  return <ThemeProvider activeScheme="dark">{children}</ThemeProvider>
}

export function useTheme() {
  return useContext(ThemeContext)
}
