import segmentPlugin from "@analytics/segment"
import Analytics, { AnalyticsInstance } from "analytics"
import React, { createContext, useContext, useEffect, useMemo } from "react"
import { datadogLogs } from "@datadog/browser-logs"

interface EarnnestAnalyticsContextInterface {
  page: AnalyticsInstance["page"]
  track: AnalyticsInstance["track"]
  identify: AnalyticsInstance["identify"]
}

const EarnnestAnalyticsContext = createContext<
  EarnnestAnalyticsContextInterface
>({
  page: async (...args) => console.log(...args),
  track: async (...args) => console.log(...args),
  identify: async (...args) => console.log(...args),
})

export function useEarnnestAnalytics() {
  return useContext(EarnnestAnalyticsContext)
}

export function EarnnestAnalyticsProvider({ children }: { children: any }) {
  useEffect(() => {
    if (process.env.REACT_APP_DATADOG_CLIENT_TOKEN) {
      datadogLogs.init({
        clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN,
        site: "datadoghq.com",
        env: process.env.REACT_APP_DATADOG_ENV,
        forwardErrorsToLogs: true,
        sampleRate: 100,
      })
    }
  }, [])

  const analytics = useMemo(() => {
    let plugins
    if (process.env.REACT_APP_SEGMENT_WRITE_KEY) {
      plugins = [
        segmentPlugin({ writeKey: process.env.REACT_APP_SEGMENT_WRITE_KEY }),
      ]
    }
    return Analytics({ app: "E2 Payment Portal", plugins })
  }, [])

  const page = analytics.page

  const identify = analytics.identify

  const track = analytics.track

  return (
    <EarnnestAnalyticsContext.Provider value={{ page, track, identify }}>
      {children}
    </EarnnestAnalyticsContext.Provider>
  )
}
