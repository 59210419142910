import React, { ElementType } from "react"
import { Pressable, PressableProps, StyleProp, ViewStyle } from "react-native"
import { Box, responsive, Text, useTheme } from "../UI"

interface ButtonProps extends PressableProps {
  alignTitle?: "left" | "center" | "right"
  title: string
  size?: "sm" | "md" | "lg"
  color?: string
  backgroundColor?: string
  kind?:
    | "primary"
    | "submit"
    | "subtle"
    | "quiet"
    | "text"
    | "textPrimary"
    | "danger"
  disabled?: boolean
  style?: StyleProp<ViewStyle>
  LeftIcon?: ElementType
  RightIcon?: ElementType
}

export default function Button(props: ButtonProps) {
  const {
    alignTitle = "center",
    title = "",
    size = "md",
    kind = "primary",
    disabled = false,
    style = null,
    onPress,
    LeftIcon,
    RightIcon,
    backgroundColor,
    ...rest
  } = props
  const { getColor, getShadow } = useTheme()
  const [hover, setHover] = React.useState(false)
  const iconSize = responsive(size === "sm" ? 12 : size === "md" ? 16 : 20)
  const iconSpace = size === "sm" ? 6 : size === "md" ? 8 : 12
  const textType =
    size === "sm" ? "heading6" : size === "md" ? "heading5" : "heading4"
  const shadowStyle = getShadow("level2")

  const handleHover = (isHovering: boolean) => {
    if (!disabled) {
      setHover(isHovering)
    }
  }

  // Button Style Mappings
  const buttonColorScheme = {
    primary: {
      default: {
        color: getColor("contrast0"),
        backgroundColor: getColor("contrast95"),
        borderColor: getColor("contrast95"),
      },
      hover: {
        backgroundColor: getColor("contrast100"),
        ...shadowStyle,
      },
      disabled: {
        color: getColor("contrast50"),
        backgroundColor: getColor("contrast20"),
        borderColor: getColor("contrast20"),
        cursor: "not-allowed",
      },
    },
    submit: {
      default: {
        color: getColor("white"),
        backgroundColor: getColor("green"),
        borderColor: getColor("green"),
      },
      hover: {
        backgroundColor: getColor("green15"),
        borderColor: getColor("green15"),
        ...shadowStyle,
      },
      disabled: {
        color: getColor("contrast50"),
        backgroundColor: getColor("contrast20"),
        borderColor: getColor("contrast20"),
        cursor: "not-allowed",
      },
    },
    subtle: {
      default: {
        color: getColor("contrast95"),
        backgroundColor: "transparent",
        borderColor: getColor("contrast95"),
      },
      hover: {
        color: getColor("contrast100"),
        borderColor: getColor("contrast100"),
        ...shadowStyle,
      },
      disabled: {
        color: getColor("contrast50"),
        borderColor: getColor("contrast50"),
        cursor: "not-allowed",
      },
    },
    quiet: {
      default: {
        color: getColor("purple90"),
        backgroundColor: getColor("white"),
        borderColor: getColor("white"),
        ...getShadow("level1"),
      },
      hover: {
        color: getColor("purple90"),
        ...getShadow("level2"),
      },
      disabled: {
        color: getColor("contrast50"),
        cursor: "not-allowed",
        boxShadow: "none",
      },
    },
    danger: {
      default: {
        color: getColor("white"),
        backgroundColor: getColor("red"),
        borderColor: getColor("red"),
      },
      hover: {
        backgroundColor: getColor("red15"),
        borderColor: getColor("red15"),
        ...shadowStyle,
      },
      disabled: {
        color: getColor("contrast50"),
        backgroundColor: getColor("contrast20"),
        borderColor: getColor("contrast20"),
        cursor: "not-allowed",
      },
    },
    text: {
      default: {
        color: getColor("green"),
        backgroundColor: "transparent",
        borderColor: "transparent",
      },
      hover: {
        color: getColor("green15"),
        backgroundColor: "transparent",
        borderColor: "transparent",
      },
      disabled: {
        color: getColor("contrast50"),
        backgroundColor: "transparent",
        borderColor: "transparent",
        cursor: "not-allowed",
      },
    },
    textPrimary: {
      default: {
        color: getColor("contrast95"),
        backgroundColor: "transparent",
        borderColor: "transparent",
      },
      hover: {
        color: getColor("contrast75"),
        backgroundColor: "transparent",
        borderColor: "transparent",
      },
      disabled: {
        color: getColor("contrast50"),
        backgroundColor: "transparent",
        borderColor: "transparent",
        cursor: "not-allowed",
      },
    },
  }

  let ButtonElement = onPress ? Pressable : Box
  let pressableProps = onPress
    ? {
        onPress: onPress,
        disabled: disabled,
        accessibilityComponentType: "button",
        accessibilityRole: "button",
        hitSlop: {
          // touch target should be at least 44
          top: size === "sm" ? 10 : size === "md" ? 4 : 0,
          bottom: size === "sm" ? 10 : size === "md" ? 4 : 0,
        },
      }
    : null

  return (
    <ButtonElement
      {...rest}
      {...pressableProps}
      // @ts-ignore
      onMouseEnter={() => handleHover(true)}
      // @ts-ignore
      onMouseLeave={() => handleHover(false)}
      style={[
        {
          flexDirection: "row",
          alignItems: "center",
          justifyContent:
            alignTitle === "left"
              ? "flex-start"
              : alignTitle === "right"
              ? "flex-end"
              : "center",
          paddingVertical: size === "sm" ? 2 : size === "md" ? 4 : 6,
          paddingHorizontal: kind === "text" || kind === "textPrimary" ? 0 : size === "sm" ? 8 : size === "md" ? 12 : 16, // prettier-ignore
          borderWidth:
            kind === "text" || kind === "textPrimary"
              ? 0
              : size === "lg"
              ? 4
              : 2,
          borderRadius: 64,
          maxWidth: "100%",
        },
        buttonColorScheme[kind].default,
        // @ts-ignore
        disabled
          ? buttonColorScheme[kind].disabled
          : hover
          ? buttonColorScheme[kind].hover
          : {},
        backgroundColor
          ? {
              backgroundColor: getColor(backgroundColor),
              borderColor: getColor(backgroundColor),
            }
          : {},
        style,
      ]}>
      {LeftIcon && (
        <LeftIcon
          style={{
            color: "inherit",
            width: iconSize,
            height: iconSize,
          }}
        />
      )}
      <Text
        numberOfLines={1}
        type={textType}
        style={{
          color: "inherit",
          textDecorationLine: "none",
          marginLeft: LeftIcon ? iconSpace : "",
          marginRight: RightIcon ? iconSpace : "",
        }}>
        {title}
      </Text>
      {RightIcon && (
        <RightIcon
          style={{
            color: "inherit",
            width: iconSize,
            height: iconSize,
          }}
        />
      )}
    </ButtonElement>
  )
}
