import { MantineTheme, MantineThemeOverride } from "@mantine/core"

const EarnnestTheme: MantineThemeOverride = {
  colors: {
    // @ts-ignore
    aqua: [
      "#D3EDEA",
      "#A7DBD4",
      "#A7DBD4",
      "#7CC8BF",
      "#50B6A9",
      "#24A494",
      "#1D8376",
      "#166259",
      "#0E423B",
      "#07211E",
    ],
    blue: [
      "#F3F9FF",
      "#D9ECFE",
      "#B3D9FD",
      "#8DC6FB",
      "#67B3FA",
      "#41A0F9",
      "#3480C7",
      "#276095",
      "#1A4064",
      "#0D2032",
    ],
    dark: [
      "#D4D4D8",
      "#ACA9B9",
      "#9592A7",
      "#7F7E90",
      "#5F5E6B",
      "#3E3E4E",
      "#343344",
      "#29283A",
      "#191823",
      "#0B0B10",
    ],
    gold: [
      "#FBF8EC",
      "#F8F1D8",
      "#F1E3B1",
      "#E9D48B",
      "#E2C664",
      "#DBB83D",
      "#AF9331",
      "#836E25",
      "#584A18",
      "#2C250C",
    ],
    gray: [
      "#F9F9F9",
      "#F4F3F8",
      "#EAE8F1",
      "#E0DEE9",
      "#D0CEDA",
      "#ACA9B9",
      "#9592A7",
      "#5F5E6B",
      "#3E3E4E",
      "#29283A",
    ],
    green: [
      "#F2FBF7",
      "#D6F4E5",
      "#ACE9CC",
      "#83DFB2",
      "#59D499",
      "#30C97F",
      "#26A166",
      "#1D794C",
      "#135033",
      "#0A2819",
    ],
    red: [
      "#FDF2EE",
      "#FBDED4",
      "#F8BCA9",
      "#F49B7E",
      "#F17953",
      "#ED5828",
      "#BE4620",
      "#8E3518",
      "#5F2310",
      "#2F1208",
    ],
  },
  black: "#29283A",
  fontFamily: "Ubuntu, sans-serif",
  primaryShade: 5,
  headings: {
    fontFamily: "Unna, serif",
    fontWeight: "normal",
    sizes: {
      h1: {
        fontSize: "3rem",
        lineHeight: 1,
      },
      h2: {
        fontSize: "2rem",
        lineHeight: 1.2,
      },
      h3: {
        fontSize: "1.5rem",
        lineHeight: 1.2,
      },
      h4: {
        fontSize: "1.25rem",
        lineHeight: 1.2,
      },
      h5: {
        fontSize: "1rem",
        lineHeight: 1.2,
      },
      h6: {
        fontSize: "0.875rem",
        lineHeight: 1.2,
      },
    },
  },
  components: {
    ActionIcon: {
      defaultProps: {
        variant: "outline",
      },
      styles: (theme, params, context) => ({
        root:
          context.variant === "outline"
            ? {
                borderRadius: "100%",
                borderWidth: 2,
                "&:hover": {
                  backgroundColor: theme.fn.themeColor(
                    theme.colorScheme === "dark" ? "dark.6" : "gray.1",
                  ),
                },
              }
            : {
                borderRadius: "100%",
              },
      }),
    },
    Alert: {
      defaultProps: {
        variant: "filled",
        color: "dark",
      },
      styles: (theme, params, context) => ({
        root: {
          borderRadius: 0,
        },
        icon: {
          marginRight: "0.5rem",
        },
        title: {
          marginBottom: 4,
        },
      }),
    },
    Anchor: {
      defaultProps: (theme) => ({
        color: theme.colorScheme === "light" ? "dark.7" : "gray.0",
        underline: true,
      }),
      styles: (theme) => ({
        root: {
          textDecoration: "underline",
        },
      }),
    },
    Avatar: {
      defaultProps: {
        radius: 100,
      },
    },
    Badge: {
      defaultProps: {
        variant: "filled",
      },
      styles: (theme, params, context) => ({
        root: {
          paddingLeft: "0.5em",
          paddingRight: "0.5em",
          borderRadius: theme.radius.sm,
        },
        inner: {
          textTransform: "none",
          color: params.color?.match(/yellow/)
            ? theme.fn.themeColor("dark")
            : undefined,
        },
      }),
    },
    Button: {
      defaultProps: {
        size: "md",
        variant: "filled",
        color: "dark.5",
        radius: 100,
        loaderPosition: "right",
      },
      styles: (theme, params, context) => ({
        root: {
          paddingLeft:
            context.size === "lg" ? theme.spacing.sm : theme.spacing.xs,
          paddingRight:
            context.size === "lg" ? theme.spacing.sm : theme.spacing.xs,
          height: context.size === "lg" ? 44 : context.size === "sm" ? 24 : 32,
          boxShadow:
            context.variant === "subtle" ? undefined : theme.shadows.xs,
          "&:hover": {
            backgroundColor:
              context.variant === "subtle"
                ? theme.fn.themeColor(
                    theme.colorScheme === "dark" ? "dark.6" : "gray.1",
                  )
                : undefined,
          },
        },
        label: {
          fontSize:
            context.size === "lg" ? 18 : context.size === "sm" ? 14 : 16,
          fontWeight: 600,
          color:
            context.variant === "subtle"
              ? theme.fn.themeColor(params.color)
              : undefined,
        },
      }),
    },
    Checkbox: {
      defaultProps: {
        size: "md",
        color: "green",
      },
      styles: (theme, params, context) => ({
        input: {
          borderWidth: 2,
          borderRadius:
            context.size === "sm" ? theme.radius.sm : theme.radius.md,
          cursor: "pointer",
        },
      }),
    },
    CheckboxGroup: {
      styles: (theme, params, context) => ({
        label: {
          fontSize: 12,
          color: theme.fn.themeColor("dark.3"),
        },
      }),
    },
    Code: {
      styles: (theme, params, context) => ({
        root: {
          backgroundColor: theme.fn.themeColor(
            theme.colorScheme === "dark" ? "dark.6" : "gray.1",
          ),
        },
      }),
    },
    DateInput: {
      styles: (theme) => ({
        day: {
          "&[data-weekend]": {
            color: theme.fn.themeColor("green"),
          },
        },
      }),
    },
    DatePickerInput: {
      styles: (theme) => ({
        day: {
          "&[data-weekend]": {
            color: theme.fn.themeColor("green"),
          },
          "&[data-in-range]": {
            backgroundColor: theme.fn.themeColor("green.0"),
            "&:hover": {
              backgroundColor: theme.fn.themeColor("green.1"),
            },
          },
          "&[data-selected]": {
            backgroundColor: theme.fn.themeColor("green") + " !important",
            color: theme.white,
          },
        },
      }),
    },
    Drawer: {
      defaultProps: {
        position: "right",
        size: "lg",
        withCloseButton: false,
      },
    },
    Input: {
      defaultProps: {
        radius: 0,
      },
      styles: (theme, params, context) => ({
        input:
          context.size === "md"
            ? {
                minHeight: "2.6rem",
                paddingLeft: 8,
                paddingRight: 8,
                fontSize: 16,
                ...inputBorderStyles(theme),
              }
            : inputBorderStyles(theme),
      }),
    },
    InputWrapper: {
      styles: (theme, params, context) => ({
        label: {
          fontSize: 12,
          fontWeight: 400,
          color: theme.fn.themeColor("dark.3"),
          paddingLeft: 8,
          paddingRight: 8,
        },
        error: {
          paddingLeft: 8,
          paddingRight: 8,
        },
        description: {
          paddingLeft: 8,
          paddingRight: 8,
        },
      }),
    },
    Loader: {
      defaultProps: {
        variant: "oval",
        color: "green",
        size: "md",
      },
    },
    Modal: {
      styles: (theme, params, context) => ({
        header: {
          backgroundColor: "transparent",
        },
        title: {
          fontSize: 18,
          fontWeight: 600,
          fontFamily: "Ubuntu, sans-serif",
        },
        close: {
          border: "none",
        },
      }),
    },
    MultiSelect: {
      defaultProps: {
        radius: 0,
      },
      styles: (theme, params, context) => ({
        item: {
          "&[data-selected]": {
            backgroundColor: theme.fn.themeColor("green") + "!important",
            "&:hover": {
              backgroundColor: theme.fn.themeColor("green.6"),
              color: theme.white,
            },
          },
        },
      }),
    },
    NavLink: {
      styles: (theme, params, context) => ({
        root: {
          borderRadius: theme.radius.md,
          "&:hover": {
            backgroundColor: theme.fn.themeColor("dark.5"),
          },
        },
      }),
    },
    Notification: {
      styles: (theme, params, context) => ({
        root: {
          border: "none",
          backgroundColor: theme.fn.themeColor(params.color, 5),
          "&::before": {
            display: "none",
          },
        },
        title: {
          color: theme.fn.themeColor("gray.0"),
          fontWeight: 600,
        },
        description: {
          color: theme.fn.themeColor("gray.0"),
        },
        closeButton: {
          color: theme.fn.themeColor("gray.0"),
          "&:hover": {
            backgroundColor: "transparent",
          },
        },
      }),
    },
    Paper: {
      styles: (theme, params, context) => ({
        root: {
          backgroundColor:
            theme.colorScheme === "dark"
              ? theme.fn.themeColor("dark.7")
              : theme.fn.themeColor("gray.0"),
        },
      }),
    },
    Radio: {
      defaultProps: {
        size: "md",
        color: "green",
      },
      styles: (theme, params, context) => ({
        radio: {
          borderWidth: 2,
        },
      }),
    },
    RadioGroup: {
      styles: (theme, params, context) => ({
        label: {
          fontSize: 12,
          color: theme.fn.themeColor("dark.3"),
        },
      }),
    },
    Select: {
      defaultProps: {
        radius: 0,
      },
      styles: (theme, params, context) => ({
        item: {
          "&[data-selected]": {
            backgroundColor: theme.fn.themeColor("green") + "!important",
            "&:hover": {
              backgroundColor: theme.fn.themeColor("green.6"),
              color: theme.white,
            },
          },
        },
      }),
    },
    Stepper: {
      defaultProps: {
        color: "green",
        size: "xs",
        icon: <></>,
      },
      styles: (theme, params, context) => ({
        step: {
          marginTop: 0,
        },
        verticalSeparator: {
          top: "100% !important",
          borderColor: theme.fn.themeColor(
            theme.colorScheme === "light" ? "gray.3" : "dark.5",
          ),
        },
        stepIcon: {
          backgroundColor: theme.fn.themeColor(
            theme.colorScheme === "light" ? "gray.0" : "dark.7",
          ),
          borderColor: theme.fn.themeColor(
            theme.colorScheme === "light" ? "gray.3" : "dark.5",
          ),
        },
        stepLabel: {
          fontSize: 16,
          fontWeight: 600,
          lineHeight: 1.1,
          color: theme.fn.themeColor(
            theme.colorScheme === "light" ? "dark.5" : "dark.1",
          ),
        },
        stepDescription: {
          fontSize: 12,
          color: theme.fn.themeColor("dark.3"),
        },
      }),
    },
    Switch: {
      defaultProps: {
        color: "green",
      },
      styles: (theme, params, context) => ({
        input: {
          "&:checked:disabled + .mantine-Switch-track": {
            backgroundColor: theme.fn.themeColor("green.1"),
            borderColor: theme.fn.themeColor("green.1"),
          },
        },
        // body: {
        //   alignItems: "center",
        // },
        track: {
          // width: "1.5rem",
          // borderWidth: 2,
          cursor: "pointer",
          "&:checked:disabled + .mantine-Switch-track": {
            cursor: "not-allowed",
          },
        },
        // thumb: {
        //   left: "2px",
        // },
      }),
    },
    Tabs: {
      defaultProps: {
        color: "green",
      },
      styles: (theme, params, context) => ({
        tab: {
          paddingLeft: 0,
          paddingRight: 0,
          marginRight: "1rem",
          color: theme.fn.themeColor("dark.3"),
          "&[data-active='true']": {
            color: theme.fn.themeColor("green"),
          },
          "&:hover": {
            background: "transparent",
          },
        },
        tabLabel: {
          fontWeight: 600,
        },
      }),
    },
    Table: {
      defaultProps: {
        verticalSpacing: "sm",
        highlightOnHover: true,
      },
      styles: (theme, params, context) => ({
        root: {
          "thead tr th": {
            color: theme.fn.themeColor(
              theme.colorScheme === "dark" ? "dark.3" : "gray.5",
            ),
            fontSize: 12,
            fontWeight: 600,
            textTransform: "uppercase",
          },
          "&[data-hover] tbody tr": {
            "&:hover": {
              cursor: "pointer",
              backgroundColor:
                theme.colorScheme === "light"
                  ? "white"
                  : theme.fn.themeColor("dark.5"),
              boxShadow: theme.shadows.sm,
              "& > td, & + tr td": {
                borderTopColor: "transparent",
              },
            },
          },
        },
      }),
    },
    Timeline: {
      defaultProps: {
        color: "green",
        bulletSize: 24,
        lineWidth: 2,
      },
      styles: (theme) => ({
        itemTitle: {
          marginBottom: 0,
        },
      }),
    },
    Text: {
      defaultProps: {
        size: "md",
      },
    },
    Textarea: {
      defaultProps: {
        radius: 0,
      },
      styles: (theme, params, context) => ({
        label: {
          fontSize: 12,
          fontWeight: 400,
          color: theme.fn.themeColor("dark.3"),
        },
        input: {
          paddingLeft: 8,
          paddingRight: 8,
          fontSize: 16,
          ...inputBorderStyles(theme),
        },
      }),
    },
    Title: {
      styles: (theme, params, context) => ({
        root: {
          fontFamily: String(context.size).match(/h1|h2/)
            ? "Unna, serif"
            : "Ubuntu, sans-serif",
          fontWeight: 600,
        },
      }),
    },
    Tooltip: {
      defaultProps: {
        size: "sm",
        withArrow: true,
        arrowSize: 6,
        arrowRadius: 2,
      },
    },
  },
  globalStyles: (theme) => ({
    a: {
      textDecoration: "none",
    },
    ".invisible-table": {
      "td, th": {
        border: "none !important",
        verticalAlign: "top !important",
      },
      "td:first-of-type, th:first-of-type": {
        paddingLeft: "0 !important",
      },
      "td:last-of-type, th:last-of-type": {
        paddingRight: "0 !important",
      },
    },
  }),
}

const inputBorderStyles = (theme: MantineTheme) => ({
  border: "none",
  borderBottom: `2px solid ${theme.fn.themeColor("gray.6")}`,
  "&:focus, &:focus-within": {
    borderBottomColor: theme.fn.themeColor("green") + "!important",
  },
})

export const DarkEarnnestTheme = {
  ...EarnnestTheme,
  colorScheme: "dark",
} as MantineThemeOverride

export const LightEarnnestTheme = EarnnestTheme
