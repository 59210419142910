import { MantineProvider } from "@mantine/core"
import { ModalsProvider } from "@mantine/modals"
import { Notifications } from "@mantine/notifications"
import { ReactNode } from "react"
import { DarkEarnnestTheme, LightEarnnestTheme } from "./theme"

export function MantineProviders({
  theme = "dark",
  children,
}: {
  theme?: "dark" | "light"
  children: ReactNode
}) {
  return (
    <MantineProvider
      theme={theme === "light" ? LightEarnnestTheme : DarkEarnnestTheme}
      withGlobalStyles
      withNormalizeCSS>
      <ModalsProvider>
        {children}
        <Notifications />
      </ModalsProvider>
    </MantineProvider>
  )
}
