import React, { useCallback, useRef, useState } from "react"
import { Animated } from "react-native"
import { useMediaQuery } from "react-responsive"
import Banner from "../Banner/Banner"
import { InfoIcon, CheckIcon } from "../Icons"

type Toast = {
  kind: "info" | "error" | "success"
  message: string
}

export const ToastContext = React.createContext<{
  triggerToast: (toast: Toast) => void
  dismissToast: () => void
}>({
  triggerToast: () => null,
  dismissToast: () => null,
})

export function useToast() {
  return React.useContext(ToastContext)
}

export function ToastProvider({ children }) {
  const isMobile = useMediaQuery({ maxWidth: 1000 })

  const [toast, setToast] = useState<Toast | null>(null)

  const timeout = useRef<number | null>(null)
  const transition = useRef(new Animated.Value(0))

  const dismissToast = useCallback(() => {
    Animated.timing(transition.current, {
      toValue: 0,
      duration: 300,
      useNativeDriver: true,
    }).start(({ finished }) => {
      if (finished) {
        setToast(null)
      }
    })
  }, [])

  const triggerToast = useCallback(
    (toast: Toast) => {
      setToast(toast)

      if (timeout.current) {
        clearTimeout(timeout.current)
        timeout.current = null
      }

      timeout.current = setTimeout(
        dismissToast,
        toast.kind === "error" ? 8000 : 5000,
      )

      transition.current.setValue(0)
      Animated.timing(transition.current, {
        toValue: 1,
        duration: 300,
        useNativeDriver: true,
      }).start()
    },
    [dismissToast],
  )

  return (
    <ToastContext.Provider
      value={{
        triggerToast,
        dismissToast,
      }}>
      {children}
      {toast ? (
        <Animated.View
          style={{
            zIndex: 900001,
            position: "absolute",
            padding: 12,
            top: isMobile ? 0 : undefined,
            right: 0,
            bottom: isMobile ? undefined : 0,
            width: isMobile ? "100%" : undefined,
            minWidth: 300,
            maxWidth: 400,
            transform: [
              {
                translateY: transition.current.interpolate({
                  inputRange: [0, 1],
                  outputRange: isMobile ? [-300, 0] : [300, 0],
                  extrapolate: "clamp",
                }),
              },
            ],
          }}>
          <Banner
            rounded
            kind={toast.kind}
            message={toast.message}
            onDismiss={toast.kind === "error" ? dismissToast : undefined}
            Icon={
              {
                info: InfoIcon,
                success: CheckIcon,
              }[toast.kind]
            }
          />
        </Animated.View>
      ) : null}
    </ToastContext.Provider>
  )
}
