export default function parseNetworkError(error: any): string {
  console.error(error)
  let code = error.code || 500
  let message = ""
  // Handle error result or message object
  let errorObj = error.result || error.message
  if (typeof errorObj === "object") {
    // Find buried error message
    message = recursiveFindMessage(errorObj)
    // Handle server exceptions
    if (errorObj.hasOwnProperty("__exception__")) {
      let info =
        errorObj.message?.reason ||
        errorObj.message?.protocol ||
        errorObj.message?.module
      if (info) {
        message = `${message} [${info}]`
      }
    }
  } else if (typeof error.message === "string") {
    if (error.message.includes("JSON Parse")) {
      message = "Unable to load data"
    }
    if (error.message.includes("code 401")) {
      message = "You are not authorized to perform this action"
    }
    message = error.message
  }
  if (!message) {
    message = "Internal server error"
  }
  return `${code}: ${message}`
}

function recursiveFindMessage(obj: any): string {
  let message = ""
  if (typeof obj === "object") {
    if (Array.isArray(obj)) {
      for (let item of obj) {
        message = recursiveFindMessage(item)
        if (message) {
          break
        }
      }
    } else {
      for (let prop in obj) {
        if (prop === "message" && typeof obj[prop] === "string") {
          message = obj[prop]
          if (message) {
            break
          }
        } else {
          message = recursiveFindMessage(obj[prop])
          if (message) {
            break
          }
        }
      }
    }
  }
  return message
}
