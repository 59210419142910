import ErrorScreen from "@earnnest-e2-frontend/platform-ui/src/ErrorScreen"
import * as Sentry from "@sentry/browser"
import React, { Component } from "react"

// Handles all things related to errors:
// - Connects Sentry (or DataDog, etc) for error monitoring.
// - Catches unexpected frontend errors and displays a top-level error boundary.
// Should be rendered at the root of our react apps.

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN as string,
  environment: process.env.REACT_APP_SENTRY_ENV as string,
  enabled: process.env.NODE_ENV === "production",
})

export class EarnnestErrorBoundary extends Component {
  state: {
    error: Error | null
  } = {
    error: null,
  }

  static getDerivedStateFromError(error) {
    return { error: new Error(error) }
  }

  componentDidCatch(error, errorInfo) {
    // error logging / reporting
  }

  render() {
    if (this.state.error) {
      return <ErrorScreen errorMessage={this.state.error.message} />
    }

    return this.props.children
  }
}
