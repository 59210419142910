import React from "react"
import Svg, { Path, SvgProps } from "react-native-svg"

export default function Logomark(props: SvgProps) {
  return (
    <Svg viewBox="0 0 135 16" fill="none" {...props}>
      <Path
        d="M11.4655 15.3235H0.0136719V13.1435H1.74784V2.58431H0.0136719V0.404297H11.304L11.7675 4.71784L9.56985 4.89865L9.10636 2.57915H4.31525V6.71188H10.9291V8.8919H4.31525V13.1383H9.2678L9.73129 10.8188L11.929 11.0048L11.4655 15.3235Z"
        fill="currentColor"
      />
      <Path
        d="M30.6874 15.3232H24.9276V13.1432H26.4535L25.6879 11.1026H20.0688L19.2616 13.1432H20.9489V15.3232H15.2568V13.1432H16.6473L21.1832 2.5892H18.9856V0.40918H23.8183L29.2292 13.1432H30.6874V15.3232ZM24.9953 9.24291L23.0268 4.05632H22.8862L20.8031 9.24291H24.9953Z"
        fill="currentColor"
      />
      <Path
        d="M47.2527 15.3235H43.7322L40.6336 9.42399H38.16V13.1435H39.9202V15.3235H33.8584V13.1435H35.5926V2.58431H33.8584V0.404297H40.5451C42.4095 0.404297 43.8051 0.79174 44.7321 1.56146C45.6591 2.33635 46.1226 3.41603 46.1226 4.81083C46.1226 5.80785 45.857 6.66022 45.3258 7.36796C44.7946 8.08085 44.0551 8.58711 43.1177 8.8919L45.4091 13.1383H47.2579V15.3235H47.2527ZM43.3885 4.88315C43.3885 4.0411 43.1438 3.44702 42.649 3.10607C42.1543 2.75996 41.3159 2.58948 40.1285 2.58948H38.16V7.38345H40.1025C41.3367 7.38345 42.1908 7.16649 42.6699 6.72738C43.149 6.29344 43.3885 5.6787 43.3885 4.88315Z"
        fill="currentColor"
      />
      <Path
        d="M66.5216 2.58432H64.7874V15.3183L63.3084 15.339L54.9813 4.99163H54.8875V13.1383H57.0852V15.3183H50.8151V13.1383H52.4816V2.58432H50.5859V0.404297H54.3563L62.2929 10.2712H62.3867V2.58432H60.189V0.404297H66.5268V2.58432H66.5216Z"
        fill="currentColor"
      />
      <Path
        d="M86.2328 2.58432H84.4987V15.3183L83.0197 15.339L74.6925 4.99163H74.5988V13.1383H76.7964V15.3183H70.5263V13.1383H72.1928V2.58432H70.292V0.404297H74.0624L81.999 10.2712H82.0927V2.58432H79.895V0.404297H86.2328V2.58432V2.58432Z"
        fill="currentColor"
      />
      <Path
        d="M101.617 15.3235H90.165V13.1435H91.8992V2.58431H90.165V0.404297H101.455L101.919 4.71784L99.7212 4.89865L99.2577 2.57915H94.4666V6.71188H101.08V8.8919H94.4666V13.1383H99.4192L99.8827 10.8188L102.08 11.0048L101.617 15.3235Z"
        fill="currentColor"
      />
      <Path
        d="M117.067 10.9377C117.067 12.4203 116.547 13.5619 115.505 14.3575C114.464 15.153 113.089 15.5508 111.375 15.5508C110.36 15.5508 109.376 15.4268 108.428 15.184C107.48 14.9412 106.725 14.5848 106.173 14.1302L106.589 10.7104L108.647 10.917L108.6 12.7509C109.11 13.0092 109.573 13.1745 110.001 13.2468C110.422 13.314 110.823 13.3501 111.193 13.3501C112.089 13.3501 112.834 13.2107 113.427 12.9265C114.021 12.6424 114.318 12.0742 114.318 11.2166C114.318 10.2816 113.979 9.66167 113.302 9.34655C112.625 9.03143 111.844 8.7938 110.964 8.62332C110.256 8.48384 109.537 8.30303 108.813 8.07057C108.089 7.84327 107.485 7.46099 107.011 6.92373C106.532 6.38648 106.293 5.60126 106.293 4.55774C106.293 2.99763 106.829 1.87663 107.902 1.19473C108.975 0.512826 110.172 0.171875 111.5 0.171875C112.427 0.171875 113.323 0.311355 114.198 0.585149C115.068 0.858942 115.75 1.22056 116.245 1.66483L116.385 4.81087L114.328 4.99168L114.12 3.06479C113.688 2.82199 113.255 2.65152 112.823 2.55853C112.391 2.46554 111.974 2.41905 111.573 2.41905C110.849 2.41905 110.245 2.5637 109.771 2.85299C109.292 3.14228 109.053 3.61238 109.053 4.25295C109.053 5.00201 109.35 5.51344 109.943 5.7769C110.537 6.04553 111.204 6.2625 111.943 6.43297C112.573 6.57245 113.292 6.75326 114.094 6.98572C114.896 7.21302 115.594 7.6263 116.187 8.21521C116.771 8.80929 117.067 9.71333 117.067 10.9377Z"
        fill="currentColor"
      />
      <Path
        d="M134.508 5.27088L132.29 5.45685L131.826 2.58976H128.889V13.1437H131.41V15.3238H123.89V13.1437H126.316V2.5846H123.358L122.895 5.45168L120.697 5.26571L121.161 0.399414H134.045L134.508 5.27088Z"
        fill="currentColor"
      />
    </Svg>
  )
}
