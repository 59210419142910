import {
  Auth0ContextInterface,
  Auth0Provider,
  useAuth0,
} from "@auth0/auth0-react"
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react"
import { useLocation } from "react-router-dom"
import { v4 as uuid } from "uuid"
import { useEarnnestAnalytics } from "./EarnnestAnalytics"

const stub = (): never => {
  throw new Error(
    "You forgot to wrap your component in <EarnnestAuth0Provider>.",
  )
}

interface EarnnestAuth0ContextInterface extends Auth0ContextInterface {
  simulated?: boolean
}

export const EarnnestAuth0Context = createContext<
  EarnnestAuth0ContextInterface
>({
  simulated: false,
  error: undefined,
  isLoading: true,
  isAuthenticated: false,
  user: null,
  getAccessTokenSilently: stub,
  getAccessTokenWithPopup: stub,
  getIdTokenClaims: stub,
  loginWithRedirect: stub,
  loginWithPopup: stub,
  logout: stub,
  buildAuthorizeUrl: stub,
  buildLogoutUrl: stub,
  handleRedirectCallback: stub,
})

export function useEarnnestAuth0() {
  const fauxAuth0Context = useContext(EarnnestAuth0Context)
  const realAuth0Context = useAuth0()
  if (fauxAuth0Context.simulated) {
    return fauxAuth0Context
  }
  return realAuth0Context
}

interface Auth0ProviderProps {
  children: any
  simulated?: boolean
}

export function EarnnestAuth0Provider({
  children,
  simulated = false,
}: Auth0ProviderProps) {
  const location = useLocation()
  const [error] = useState(undefined)
  const [isLoading, setIsLoading] = useState(true)
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const [user, setUser] = useState<any>(null)
  const { track } = useEarnnestAnalytics()

  useEffect(() => {
    setIsAuthenticated(Boolean(localStorage.getItem("fauxAuth0Token")))
    setIsLoading(false)
  }, [])

  const auth0OptionsFromSso = useMemo(() => {
    let params = {}
    const sp = new URLSearchParams(location.search)
    if (!sp.has("sso")) {
      return params
    }

    // sso=<param>
    //
    // The sso param is encoded first as base64, then URL encoded.
    // We have to do the reverse of that to get the original SSO params
    // as a query string "a=b&b=c&d=e".
    const encodedSso = sp.get("sso") as string
    const decodedSso = atob(decodeURIComponent(encodedSso))

    // Finally, we pull out the query string into a URLSearchParams interface
    // for easy maneuverability. Then map them onto the params object.
    const ssoSearchParams = new URLSearchParams(decodedSso)
    ssoSearchParams.forEach((value, key) => {
      params = { ...params, [key]: value }
    })
    // for (let [key, value] of ssoSearchParams.entries()) {
    //   params = { ...params, [key]: value }
    // }

    return {
      ...params,
      // Force sync means that even for non-interactive authentications (i.e.
      // silent auth), it will make sure to synchronize the Auth0 data with
      // Earnnest's system.
      //
      // This ensures that any updated data from an integration partner is
      // carried over to Earnnest, even if the user happens to already be
      // authenticated when accessing Earnnest from an integrator.
      earnnest_force_sync: "true",
    }
  }, [location.search])

  if (simulated) {
    return (
      <EarnnestAuth0Context.Provider
        value={{
          simulated: true,
          error,
          isLoading,
          isAuthenticated,
          user,
          // @ts-ignore
          getAccessTokenSilently: async () => {
            if (isAuthenticated) {
              return localStorage.getItem("fauxAuth0Token") || "TOKEN"
            } else {
              throw new Error("Not authorized")
            }
          },
          getAccessTokenWithPopup: async () => {
            if (isAuthenticated) {
              return localStorage.getItem("fauxAuth0Token") || "TOKEN"
            } else {
              throw new Error("Not authorized")
            }
          },
          getIdTokenClaims: async () => {
            if (isAuthenticated) {
              return { __raw: "IDTOKENCLAIM" }
            } else {
              throw new Error("Not authorized")
            }
          },
          loginWithRedirect: async () => {
            localStorage.setItem("fauxAuth0Token", uuid())
            setIsAuthenticated(true)
            setUser({})
          },
          loginWithPopup: async () => {
            localStorage.setItem("fauxAuth0Token", uuid())
            setIsAuthenticated(true)
            setUser({})
          },
          logout: () => {
            localStorage.removeItem("fauxAuth0Token")
            setIsAuthenticated(false)
            setUser(null)
          },
        }}>
        {children}
      </EarnnestAuth0Context.Provider>
    )
  }

  const customParams = {
    earnnest_app_override: process.env.REACT_APP_AUTH0_APP_OVERRIDE,
    earnnest_api_override: process.env.REACT_APP_AUTH0_API_OVERRIDE,
    earnnest_api_sync_override: process.env.REACT_APP_AUTH0_SYNC_OVERRIDE,
  }

  Object.keys(customParams).forEach((k) => {
    if (!customParams[k]) {
      delete customParams[k]
    }
  })

  return (
    <Auth0Provider
      {...customParams}
      {...auth0OptionsFromSso}
      useRefreshTokens={true}
      domain={process.env.REACT_APP_AUTH0_DOMAIN as string}
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID as string}
      audience={process.env.REACT_APP_AUTH0_AUDIENCE as string}
      cookieDomain={process.env.REACT_APP_AUTH0_COOKIE_DOMAIN as string}
      redirectUri={`${window.location.origin}/callback`}
      cacheLocation="localstorage"
      onRedirectCallback={(appState) => {
        console.log("onRedirectCallback")
        track("Login Succeeded")
        window.location.replace(appState?.returnTo || "/")
      }}>
      {children}
    </Auth0Provider>
  )
}
