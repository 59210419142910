import FocusTrap from "focus-trap-react"
import React, {
  createContext,
  RefObject,
  useEffect,
  useRef,
  useState,
} from "react"
import {
  Animated,
  ScrollView,
  StyleSheet,
  TouchableWithoutFeedback,
} from "react-native"
import { useMediaQuery } from "react-responsive"
import Button from "../Button/Button"
import { Box, useTheme } from "../UI"

export interface PanelProps {
  side?: "left" | "right"
  floating?: boolean
  width?: number
  overlay?: boolean
  closeLabel?: string
  onClose?: () => void
  onBack?: () => void
  backLabel?: string
  children?: any
}

export const PanelContext = createContext({ footerNode: null })

export default function Panel({
  side = "left",
  floating = false,
  width = 600,
  overlay = true,
  closeLabel = "Close",
  onClose,
  backLabel = "Back",
  onBack,
  children,
}: PanelProps) {
  const { getColor } = useTheme()
  const isMobile = useMediaQuery({ maxWidth: 1000 })

  // Slide panel up (mobile) or in (desktop).
  const visible = !!children
  const transition = useRef(new Animated.Value(visible ? 1 : 0))
  useEffect(() => {
    Animated.timing(transition.current, {
      toValue: visible ? 1 : 0,
      duration: 200,
      useNativeDriver: true,
    }).start()
  }, [visible])

  // Reset scroll position whenever panel changes
  const scrollRef: RefObject<ScrollView> = useRef(null)
  useEffect(() => {
    if (children && scrollRef.current) {
      scrollRef.current.scrollTo({ x: 0, y: 0, animated: false })
    }
  }, [children])

  const footerRef = useRef(null)
  const [footerNode, setFooterNode] = useState(null)
  useEffect(() => {
    setFooterNode(footerRef.current)
  }, [visible])

  return (
    <>
      {overlay ? (
        <Animated.View
          pointerEvents={visible ? "auto" : "none"}
          style={[
            StyleSheet.absoluteFill,
            {
              zIndex: 9000,
              opacity: transition.current.interpolate({
                inputRange: [0, 1],
                outputRange: [0, 0.8],
              }),
            },
          ]}>
          <TouchableWithoutFeedback onPress={onClose}>
            <Box bg="purple" style={{ flex: 1 }} />
          </TouchableWithoutFeedback>
        </Animated.View>
      ) : null}
      <Animated.View
        style={{
          zIndex: 9001,
          position: "fixed",
          top: 0,
          bottom: 0,
          left: side === "left" ? 0 : undefined,
          right: side === "right" ? 0 : undefined,
          width: isMobile ? "100%" : width,
          backgroundColor: getColor("contrast05"),
          ...(floating && !isMobile ? { margin: 8, borderRadius: 12 } : {}),
          opacity: transition.current.interpolate({
            inputRange: [0, 1],
            outputRange: [0, 1],
          }),
          transform: [
            {
              translateX: isMobile
                ? 0
                : transition.current.interpolate({
                    inputRange: [0, 1],
                    outputRange: side === "right" ? [width, 0] : [-width, 0],
                  }),
            },
            {
              translateY: isMobile
                ? transition.current.interpolate({
                    inputRange: [0, 1],
                    outputRange: ["100%", "0%"],
                  })
                : 0,
            },
          ],
        }}>
        {visible ? (
          <FocusTrap
            focusTrapOptions={{
              initialFocus: false,
              escapeDeactivates: false,
              allowOutsideClick: true,
              clickOutsideDeactivates: false,
            }}>
            <Box style={{ flex: 1 }}>
              <ScrollView
                ref={scrollRef}
                contentContainerStyle={{ minHeight: "90vh" }}>
                <Box
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    right: 0,
                  }}>
                  <Box
                    p={24}
                    style={{
                      width: "100%",
                      maxWidth: 540,
                      alignSelf: "center",
                      flexDirection: "row",
                    }}>
                    {onBack ? (
                      <Button
                        kind="textPrimary"
                        title={backLabel}
                        onPress={onBack}
                      />
                    ) : null}
                    <Box style={{ flex: 1 }} />
                    {onClose ? (
                      <Button
                        kind="textPrimary"
                        title={closeLabel}
                        onPress={onClose}
                      />
                    ) : null}
                  </Box>
                </Box>
                <Box
                  px={24}
                  py={72}
                  // @ts-ignore
                  style={{
                    position: "static",
                    width: "100%",
                    maxWidth: 540,
                    minHeight: "calc(100vh - 16px)",
                    alignSelf: "center",
                    alignItems: "stretch",
                    justifyContent: "center",
                  }}>
                  <PanelContext.Provider value={{ footerNode }}>
                    {children}
                  </PanelContext.Provider>
                </Box>
              </ScrollView>
              <div ref={footerRef} />
            </Box>
          </FocusTrap>
        ) : null}
      </Animated.View>
    </>
  )
}
