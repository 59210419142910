import { useEarnnestAnalytics } from "@earnnest-e2-frontend/platform-api/src/providers/EarnnestAnalytics"
import React, { useEffect } from "react"
import { Redirect, useLocation } from "react-router-dom"

// Handles logout redirects from Auth0

export default function LogoutPage() {
  const location = useLocation()
  const { track } = useEarnnestAnalytics()
  const searchParams = new URLSearchParams(location.search)
  const redirectTo = searchParams.get("redirectTo")

  useEffect(() => {
    track("Logout Succeeded")
  }, [track])

  if (redirectTo) {
    return <Redirect to={redirectTo} />
  }

  return <Redirect to="/" />
}
