import * as AbsintheSocket from "@absinthe/socket"
import { createAbsintheSocketLink } from "@absinthe/socket-apollo-link"
import {
  ApolloClient,
  ApolloError,
  ApolloProvider,
  from,
  HttpLink,
  InMemoryCache,
  Observable,
  split,
} from "@apollo/client"
import { setContext } from "@apollo/client/link/context"
import { onError } from "@apollo/client/link/error"
import { WebSocketLink } from "@apollo/client/link/ws"
import { getMainDefinition } from "@apollo/client/utilities"
import { Anchor, Center, Paper, Stack, Text, Title } from "@mantine/core"
import moment from "moment"
import { Socket as PhoenixSocket } from "phoenix"
import React, { createContext, useContext, useMemo } from "react"
import { typePolicies } from "../graphql/typePolicies"
import parseNetworkError from "../utils/parseNetworkError"
import { useEarnnestAuth0 } from "./EarnnestAuth0"

export interface EarnnestAPIContextInterface {
  publicAPI: { get: (path: string) => Promise<any> }
  privateAPI: { get: (path: string, data?: object) => Promise<any> }
}

export const EarnnestAPIContext = createContext<EarnnestAPIContextInterface>({
  publicAPI: { get: async () => null },
  privateAPI: { get: async () => null },
})

export function useEarnnestAPI() {
  return useContext(EarnnestAPIContext)
}

export function EarnnestAPIProvider({ children }: { children: any }) {
  // const [anonId] = useState(() => uuid())

  const {
    user,
    isAuthenticated,
    getIdTokenClaims,
    getAccessTokenSilently,
  } = useEarnnestAuth0()

  const apolloClient = useMemo(() => {
    const errorLink = onError(({ graphQLErrors, networkError }) => {
      if (networkError) {
        return new Observable((obs) => {
          obs.error(
            new ApolloError({
              errorMessage: parseNetworkError(networkError),
              graphQLErrors,
              networkError,
            }),
          )
        })
      }
      if (graphQLErrors) {
        graphQLErrors.forEach((error) => console.error(error))
      }
    })

    const httpAuthLink = setContext(async () => {
      if (isAuthenticated) {
        const idToken = await getIdTokenClaims()
        const accessToken = await getAccessTokenSilently()
        return {
          headers: {
            authorization: accessToken ? `Bearer ${accessToken}` : "",
            "x-user-id": user?.sub || "",
            "x-earnnest-session-id": idToken.__raw,
            // "x-earnnest-request-id": user?.sub
            //   ? `${user.sub}-${Date.now()}`
            //   : `anon-${anonId}-${Date.now()}`,
          },
        }
      }
    })

    const httpLink = new HttpLink({
      uri: process.env.REACT_APP_GQL_API_HTTP_URI,
    })

    const httpLinks = from([errorLink, httpAuthLink, httpLink])

    if (process.env.REACT_APP_GQL_API_WEBSOCKET_URI) {
      let socketLink
      if (process.env.REACT_APP_GQL_API_MOCKED) {
        socketLink = new WebSocketLink({
          uri: process.env.REACT_APP_GQL_API_WEBSOCKET_URI + "/websocket",
          options: {
            reconnect: true,
            connectionParams: {
              "x-user-id": user?.sub || "",
            },
          },
        })
      } else {
        socketLink = createAbsintheSocketLink(
          AbsintheSocket.create(
            new PhoenixSocket(process.env.REACT_APP_GQL_API_WEBSOCKET_URI, {
              params: {
                "x-gateway-secret": process.env.REACT_APP_API_GATEWAY_SECRET,
                "x-user-id": user?.sub || "",
                // "x-earnnest-request-id": user?.sub
                //   ? `${user.sub}-${Date.now()}`
                //   : `anon-${anonId}-${Date.now()}`,
              },
            }),
          ),
        )
      }

      const splitLink = split(
        ({ query }) => {
          // @ts-ignore
          const { kind, operation } = getMainDefinition(query)
          return kind === "OperationDefinition" && operation === "subscription"
        },
        socketLink,
        httpLinks,
      )

      return new ApolloClient({
        cache: new InMemoryCache({ typePolicies }),
        link: splitLink,
      })
    } else {
      console.warn("Websocket not configured")

      return new ApolloClient({
        cache: new InMemoryCache({ typePolicies }),
        link: httpLinks,
      })
    }
  }, [user, isAuthenticated, getIdTokenClaims, getAccessTokenSilently])

  const publicAPI = useMemo(
    () => ({
      get: async (path) => {
        return await fetch(process.env.REACT_APP_PUBLIC_API_HTTP_URI + path)
      },
    }),
    [],
  )

  const privateAPI = useMemo(
    () => ({
      get: async (path) => {
        const accessToken = await getAccessTokenSilently()
        return await fetch(process.env.REACT_APP_PRIVATE_API_HTTP_URI + path, {
          method: "get",
          headers: {
            authorization: accessToken ? `Bearer ${accessToken}` : "",
          },
        })
      },
    }),
    [getAccessTokenSilently],
  )

  const maintenanceStart = process.env.REACT_APP_MAINTENANCE_START
  if (
    maintenanceStart &&
    moment(maintenanceStart).isValid() &&
    moment().isAfter(maintenanceStart)
  ) {
    return (
      <Paper
        radius={0}
        sx={{ position: "fixed", top: 0, left: 0, right: 0, bottom: 0 }}>
        <Stack h="100vh" justify="center" align="center">
          <Title order={3} align="center">
            Down For Maintenance
          </Title>
          <Text align="center" maw="30em">
            Earnnest is undergoing scheduled maintenance.{"\n"}If you have any
            questions, please contact{" "}
            <Anchor color="green.7" href="mailto:support@earnnest.com">
              support@earnnest.com
            </Anchor>
          </Text>
        </Stack>
      </Paper>
    )
  }

  return (
    <EarnnestAPIContext.Provider value={{ publicAPI, privateAPI }}>
      <ApolloProvider client={apolloClient}>{children}</ApolloProvider>
      {maintenanceStart && moment(maintenanceStart).isValid() ? (
        <Center
          bg="green"
          sx={{
            zIndex: 9999,
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            height: 24,
          }}>
          <Text size="sm" color="white" align="center">
            Scheduled maintenance will start{" "}
            {moment(maintenanceStart).calendar()}
          </Text>
        </Center>
      ) : null}
    </EarnnestAPIContext.Provider>
  )
}
