import {
  UserPermission,
  useUserQuery,
} from "@earnnest-e2-frontend/platform-api/src/graphql"
import { useEarnnestAuth0 } from "@earnnest-e2-frontend/platform-api/src/providers/EarnnestAuth0"
import UserAvatar from "@earnnest-e2-frontend/platform-ui/src/mantine/UserAvatar"
import {
  Anchor,
  Button,
  Menu,
  ScrollArea,
  Text,
  useMantineTheme,
} from "@mantine/core"
import { openModal } from "@mantine/modals"
import pluralize from "pluralize"
import React from "react"
import { RiArrowDropDownFill } from "react-icons/ri"

export default function UserNavControls({
  showContactSupport,
}: {
  showContactSupport?: boolean
}) {
  const { logout } = useEarnnestAuth0()
  const theme = useMantineTheme()
  const userQuery = useUserQuery()
  const user = userQuery.data?.user

  if (!user) {
    return null
  }

  return (
    <Menu shadow="md" width={200}>
      <Menu.Target>
        <Button
          variant={theme.colorScheme === "light" ? "white" : "filled"}
          leftIcon={<UserAvatar user={user} />}
          rightIcon={<RiArrowDropDownFill />}
          styles={(theme) => ({
            root: {
              paddingLeft: 4,
              paddingRight: 4,
            },
            leftIcon: {
              marginRight: 0,
            },
            rightIcon: {
              marginLeft: 0,
            },
            label: {
              marginLeft: 8,
              marginRight: 8,
              [theme.fn.smallerThan("md")]: {
                display: "none",
              },
            },
          })}>
          {user.fullName}
        </Button>
      </Menu.Target>
      <Menu.Dropdown>
        <ScrollArea.Autosize mah={600}>
          <Menu.Label>{user.email}</Menu.Label>
          {user?.roles?.includes(UserPermission.RequestEscrow) ? (
            <>
              <Menu.Divider />
              <Menu.Label>AGENT</Menu.Label>
              <Menu.Item
                component="a"
                href={`${process.env.REACT_APP_AGENT_DOMAIN}/requests`}>
                Request Payment
              </Menu.Item>
            </>
          ) : null}
          {user.organizations?.length ? (
            <>
              <Menu.Divider />
              <Menu.Label>
                {pluralize("ORGANIZATION", user.organizations.length)}
              </Menu.Label>
              {user.organizations.map((org, i) => (
                <Menu.Item
                  key={i}
                  component="a"
                  href={`${process.env.REACT_APP_DASHBOARD_DOMAIN}/${org.slug}`}>
                  {org.name}
                </Menu.Item>
              ))}
            </>
          ) : null}
          {user.role === "superadmin" ? (
            <>
              <Menu.Divider />
              <Menu.Label>SUPERADMIN</Menu.Label>
              <Menu.Item
                component="a"
                href={`${process.env.REACT_APP_SUPPORT_DOMAIN}`}>
                Manage Earnnest
              </Menu.Item>
            </>
          ) : null}
          <Menu.Divider />
          {showContactSupport ? (
            <Menu.Item
              onClick={() => {
                openModal({
                  title: "Contact Support",
                  children: (
                    <Text>
                      Earnnest is here to help. For support questions please
                      contact us at{" "}
                      <Anchor color="green" href="mailto:support@earnnest.com">
                        support@earnnest.com
                      </Anchor>{" "}
                      or call{" "}
                      <Anchor color="green" href="tel:888-870-2336">
                        (888) 870-2336
                      </Anchor>
                    </Text>
                  ),
                })
              }}>
              Contact Support
            </Menu.Item>
          ) : null}
          <Menu.Item
            onClick={() => {
              logout({
                returnTo: `${
                  window.location.origin
                }/logout?redirectTo=${encodeURIComponent(
                  window.location.pathname,
                )}`,
              })
            }}>
            Log out
          </Menu.Item>
        </ScrollArea.Autosize>
      </Menu.Dropdown>
    </Menu>
  )
}
