export const typePolicies = {
  Query: {
    fields: {
      payments: {
        // Cache separate results based on these arguments
        // keyArgs: [],
        // How to merge paged result with existing
        merge: mergePagedEntriesById,
      },
      paymentGenerators: {
        // Cache separate results based on these arguments
        // keyArgs: [],
        // How to merge paged result with existing
        merge: mergePagedEntriesById,
      },
      paymentOccasions: {
        // Cache separate results based on these arguments
        // keyArgs: [],
        // How to merge paged result with existing
        merge: mergePagedEntriesById,
      },
      organizations: {
        // Cache separate results based on these arguments
        // keyArgs: [],
        // How to merge paged result with existing
        merge: mergePagedEntriesById,
      },
    },
  },
}

function mergePagedEntriesById(existing, incoming, { readField }) {
  if (existing) {
    let entriesMap = new Map()
    existing.entries.forEach((ref) => {
      let id = readField("id", ref)
      entriesMap.set(id, ref)
    })
    incoming.entries.forEach((ref) => {
      let id = readField("id", ref)
      entriesMap.set(id, ref)
    })
    return {
      ...existing,
      pageInfo: incoming.pageInfo,
      entries: Array.from(entriesMap.values()),
    }
  } else {
    return incoming
  }
}
