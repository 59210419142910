import { UserFragment } from "@earnnest-e2-frontend/platform-api/src/graphql"
import { Avatar, AvatarProps } from "@mantine/core"
import seedrandom from "seedrandom"

interface UserAvatarProps extends AvatarProps {
  user: UserFragment
}

const avatarColors = [
  "blue.4",
  "green.4",
  "aqua.4",
  "gold.4",
  "blue.5",
  "green.5",
  "aqua.5",
  "gold.5",
  "blue.6",
  "green.6",
  "aqua.6",
  "gold.6",
]

export default function UserAvatar({ user, ...rest }: UserAvatarProps) {
  const random = seedrandom(user.id)
  const color = avatarColors[Math.floor(random() * avatarColors.length)]
  return (
    <Avatar size={24} variant="filled" color={color} {...rest}>
      {user.firstName.charAt(0)}
      {user.lastName.charAt(0)}
    </Avatar>
  )
}
