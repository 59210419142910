import {
  TransactionTemplateFragment,
  useResendReceiptMutation,
} from "@earnnest-e2-frontend/platform-api/src/graphql"
import { PanelFooter } from "@earnnest-e2-frontend/platform-ui/src/mantine/Panel"
import {
  Badge,
  Button,
  Center,
  Checkbox,
  Group,
  Select,
  Stack,
  Text,
  Title,
  useMantineTheme,
} from "@mantine/core"
import { useForm, yupResolver } from "@mantine/form"
import { notifications } from "@mantine/notifications"
import { RiArrowDownSFill } from "react-icons/ri"
import * as yup from "yup"

export default function ReceiptResender({
  transactionTemplate,
}: {
  transactionTemplate: TransactionTemplateFragment
}) {
  const theme = useMantineTheme()

  const [resendReceipt, resendReceiptState] = useResendReceiptMutation()

  const transaction = transactionTemplate?.transactions?.[0]

  const emailOptionSchema = yup.object().shape({
    email: yup.string().email("Invalid Email").required("Required"),
    type: yup.string().required("Required"),
  })

  const receiptForm = useForm({
    initialValues: {
      emailType: "",
      emailOptions: getDefaultEmailOptions(transactionTemplate),
      emailsChecked: [],
    },
    validate: yupResolver(
      yup.object().shape({
        emailType: yup.string().required("Required"),
        emailOptions: yup.array().of(emailOptionSchema),
        emailsChecked: yup
          .array()
          .of(emailOptionSchema)
          .min(1, "Select at least one email")
          .required("Required"),
      }),
    ),
  })

  const addEmailForm = useForm({
    initialValues: {
      email: "",
      type: "buyer",
    },
    validate: yupResolver(emailOptionSchema),
  })

  return (
    <>
      <form
        onSubmit={receiptForm.onSubmit(async (values) => {
          try {
            await resendReceipt({
              variables: {
                id: transaction?.id,
                emailType: values.emailType,
                emails: values.emailsChecked,
              },
            })
            notifications.show({
              color: "green",
              title: "Success",
              message: "Receipt resent successfully",
            })
            receiptForm.reset()
          } catch (error) {
            console.error(error)
            notifications.show({
              color: "red",
              title: "Error",
              message: error.message,
            })
          }
        })}>
        <Stack>
          <Title order={4}>Resend email receipts</Title>
          <Select
            placeholder="Select receipt type"
            {...receiptForm.getInputProps("emailType")}
            data={[
              {
                label: "Paid",
                value: "initiated",
                disabled: !transactionTemplate.transactions?.[0]?.initiatedAt,
              },
              {
                label: "Deposited",
                value: "completed",
                disabled: !transactionTemplate.transactions?.[0]?.depositedAt,
              },
            ]}
          />
        </Stack>
        {receiptForm.values.emailType ? (
          <PanelFooter>
            <Button
              size="lg"
              type="submit"
              disabled={resendReceiptState.loading || !receiptForm.isValid()}>
              {resendReceiptState.loading ? "Sending..." : "Send emails"}
            </Button>
          </PanelFooter>
        ) : null}
      </form>
      {receiptForm.values.emailType ? (
        <>
          <Stack mt="lg" spacing="xs">
            <Stack spacing="xs">
              {receiptForm.values.emailOptions.map((option) => {
                const checked = Boolean(
                  receiptForm.values.emailsChecked.find(
                    (x) => x.email === option.email && x.type === option.type,
                  ),
                )
                if (
                  receiptForm.values.emailType === "completed" &&
                  option.type === "agent"
                ) {
                  return null
                }
                return (
                  <Checkbox
                    key={`${option.email}_${option.type}`}
                    value={option.email}
                    checked={checked}
                    styles={{
                      labelWrapper: {
                        width: "100%",
                      },
                    }}
                    label={
                      <Group w="100%" position="apart">
                        <Text>{option.email}</Text>
                        <Badge color="gray">{option.type.toUpperCase()}</Badge>
                      </Group>
                    }
                    onChange={() => {
                      receiptForm.setFieldValue(
                        "emailsChecked",
                        checked
                          ? receiptForm.values.emailsChecked.filter(
                              (x) =>
                                x.email !== option.email ||
                                x.type !== option.type,
                            )
                          : receiptForm.values.emailsChecked.concat(option),
                      )
                    }}
                  />
                )
              })}
            </Stack>
            <form
              onSubmit={addEmailForm.onSubmit((values) => {
                if (
                  receiptForm.values.emailOptions.find(
                    (x) => values.email === x.email && values.type === x.type,
                  )
                ) {
                  notifications.show({
                    color: "red",
                    title: "Error",
                    message: "Email already added",
                  })
                } else {
                  receiptForm.setValues({
                    emailOptions: [...receiptForm.values.emailOptions, values],
                    emailsChecked: [
                      ...receiptForm.values.emailsChecked,
                      values,
                    ],
                  })
                  addEmailForm.reset()
                }
              })}>
              <Group pos="relative" align="center" spacing="xs" noWrap pl={36}>
                <input
                  placeholder="Add an email..."
                  style={{
                    appearance: "none",
                    outline: "none",
                    border: "none",
                    width: "100%",
                    padding: 0,
                    fontFamily: "Ubuntu",
                    fontStyle: "normal",
                    fontWeight: "normal",
                    fontSize: "1em",
                    lineHeight: 1.4,
                    backgroundColor: "transparent",
                    color: theme.fn.themeColor("dark"),
                  }}
                  {...addEmailForm.getInputProps("email")}
                />
                {addEmailForm.isValid() ? (
                  <button
                    type="submit"
                    style={{
                      border: "none",
                      outline: "none",
                      appearance: "none",
                      background: "transparent",
                      cursor: "pointer",
                      color: theme.fn.themeColor("dark"),
                    }}>
                    ↵
                  </button>
                ) : null}
                <select
                  style={{
                    border: "none",
                    outline: "none",
                    appearance: "none",
                    padding: "0 1.6em 0 0.6em",
                    borderRadius: 4,
                    backgroundColor: theme.fn.themeColor("gray"),
                    textTransform: "uppercase",
                    color: theme.fn.themeColor("dark"),
                    fontSize: 11,
                    fontWeight: 700,
                    fontFamily: "Ubuntu, sans-serif",
                    letterSpacing: "0.25px",
                    lineHeight: "20px",
                    width: addEmailForm.values.type === "escrow" ? "auto" : 60,
                  }}
                  {...addEmailForm.getInputProps("type")}>
                  <option value="buyer">Buyer</option>
                  {receiptForm.values.emailType === "completed" ? null : (
                    <option value="agent">Agent</option>
                  )}
                  <option value="escrow">Escrow</option>
                </select>
                <Center
                  style={{
                    position: "absolute",
                    top: 1,
                    right: 0,
                    width: 20,
                    height: 20,
                    pointerEvents: "none",
                    color: theme.fn.themeColor("dark"),
                  }}>
                  <RiArrowDownSFill />
                </Center>
              </Group>
            </form>
          </Stack>
        </>
      ) : null}
    </>
  )
}

function getDefaultEmailOptions(
  transactionTemplate: TransactionTemplateFragment,
) {
  let escrowEmails = new Set()
  let agentEmails = new Set()
  let buyerEmails = new Set()
  if (transactionTemplate.buyer?.email) {
    buyerEmails.add(transactionTemplate.buyer?.email)
  }
  if (transactionTemplate.receivingOrganization?.email) {
    escrowEmails.add(transactionTemplate.receivingOrganization?.email)
  }
  if (transactionTemplate.buyerAgent?.email) {
    agentEmails.add(transactionTemplate.buyerAgent?.email)
  }
  if (transactionTemplate.listingAgent?.email) {
    agentEmails.add(transactionTemplate.listingAgent?.email)
  }
  if (transactionTemplate.additionalEmails?.length > 0) {
    transactionTemplate.additionalEmails?.forEach((ae) => agentEmails.add(ae))
  }
  if (transactionTemplate.receivingOrganization?.emails?.length > 0) {
    transactionTemplate.receivingOrganization?.emails?.forEach((e) =>
      escrowEmails.add(e),
    )
  }
  if (transactionTemplate.escrowAccount?.emails?.length > 0) {
    transactionTemplate.escrowAccount?.emails?.forEach((e) =>
      escrowEmails.add(e),
    )
  }
  if (transactionTemplate.paymentOccasion?.emails?.length > 0) {
    transactionTemplate.paymentOccasion?.emails?.forEach((e) =>
      escrowEmails.add(e),
    )
  }
  const emailList = [
    ...Array.from(buyerEmails).map((be) => ({ type: "buyer", email: be })),
    ...Array.from(agentEmails).map((ae) => ({ type: "agent", email: ae })),
    ...Array.from(escrowEmails).map((ee) => ({ type: "escrow", email: ee })),
  ]
  return emailList
}
