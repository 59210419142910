import * as React from "react"
import Svg, { Path } from "react-native-svg"

function SupportIcon(props) {
  return (
    <Svg width={14} height={10} viewBox="0 0 14 10" fill="none" {...props}>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.875 1.733V1.25h.438A.427.427 0 008.75.833V.417A.427.427 0 008.312 0H5.688a.427.427 0 00-.438.417v.416c0 .23.196.417.438.417h.437v.483C3.158 2.138.875 4.562.875 7.5h12.25c0-2.938-2.283-5.362-5.25-5.767zm5.688 6.6H.437A.427.427 0 000 8.75v.833c0 .23.196.417.438.417h13.124A.427.427 0 0014 9.583V8.75a.427.427 0 00-.438-.417z"
        fill="currentColor"
      />
    </Svg>
  )
}

export default SupportIcon
