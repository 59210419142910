import { useTransactionTemplatesQuery } from "@earnnest-e2-frontend/platform-api/src/graphql"
import InlineFilterList from "@earnnest-e2-frontend/platform-ui/src/mantine/InlineFilterList"
import TransactionsTable from "@earnnest-e2-frontend/platform-views/src/mantine/TransactionsTable"
import { Space } from "@mantine/core"
import { useDebouncedValue } from "@mantine/hooks"
import moment from "moment"
import { useMemo } from "react"
import { useLocation, useParams } from "react-router-dom"

export default function TransactionsPage() {
  const params = useParams()
  const location = useLocation()

  const searchParamsObj = useMemo(() => {
    const searchParams = new URLSearchParams(location.search)
    return Object.fromEntries(searchParams)
  }, [location.search])

  const [debouncedSearchParams] = useDebouncedValue(searchParamsObj, 700)

  const transactionTemplatesQuery = useTransactionTemplatesQuery({
    notifyOnNetworkStatusChange: true, // so loading status updates for pagination
    variables: {
      limit: 20,
      integrationId: params.integrationId, // TODO: require?
      id: debouncedSearchParams.id || undefined,
      traceId: debouncedSearchParams.trace_id || undefined,
      startAt: debouncedSearchParams.start_at
        ? moment(debouncedSearchParams.start_at).startOf("day").toISOString()
        : undefined,
      endAt: debouncedSearchParams.end_at
        ? moment(debouncedSearchParams.end_at).endOf("day").toISOString()
        : null,
    },
  })

  return (
    <>
      <InlineFilterList
        filters={{
          id: { label: "Transaction ID", type: "id" },
          trace_id: { label: "EN-ID", type: "text" },
          start_at: { label: "After", type: "date" },
          end_at: { label: "Before", type: "date" },
        }}
      />
      <Space h="lg" />
      <TransactionsTable
        query={transactionTemplatesQuery}
        getItemLink={(transactionTemplate) => ({
          pathname: `/${params.integrationId}/transactions/${transactionTemplate.id}`,
          search: location.search,
          state: { background: location },
        })}
        viewerRole="admin"
      />
    </>
  )
}
