import React from "react"
import {
  StyleProp,
  Text as RNText,
  TextStyle as RNTextStyle,
} from "react-native"
import { useTheme } from "./Theme"
import { TextProps } from "./types"

// Thin layer on top of Text to support typography and color from Theme

export default function EarnnestText({
  type,
  color,
  center,
  link,
  style,
  ...rest
}: TextProps) {
  const { getColor, getTypography } = useTheme()

  let colorStyle: StyleProp<RNTextStyle>
  if (color) {
    colorStyle = {
      color: getColor(color),
    }
  }

  let typeStyle: StyleProp<RNTextStyle>
  if (type) {
    typeStyle = getTypography(type)
  }

  let centerStyle: StyleProp<RNTextStyle>
  if (center) {
    centerStyle = { textAlign: "center" }
  }

  let linkStyle: StyleProp<RNTextStyle>
  if (link || rest.href) {
    linkStyle = { textDecorationLine: "underline" }
  }

  return (
    <RNText
      {...rest}
      accessibilityRole={link || rest.href ? "link" : undefined}
      style={[typeStyle, colorStyle, centerStyle, linkStyle, style]}
    />
  )
}
