import * as React from "react"
import Svg, { Path } from "react-native-svg"

function CheckIcon(props) {
  return (
    <Svg width={15} height={12} viewBox="0 0 15 12" fill="none" {...props}>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.214 1.092a1.56 1.56 0 01.041 2.154l-7.085 7.62a1.45 1.45 0 01-1.102.467 1.455 1.455 0 01-1.077-.529L1.024 6.042a1.56 1.56 0 01.154-2.149 1.44 1.44 0 012.079.16l2.91 3.494 5.963-6.413a1.44 1.44 0 012.084-.042z"
        fill="currentColor"
      />
    </Svg>
  )
}

export default CheckIcon
