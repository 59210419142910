import { useIntegrationQuery } from "@earnnest-e2-frontend/platform-api/src/graphql"
import { useEarnnestAuth0 } from "@earnnest-e2-frontend/platform-api/src/providers/EarnnestAuth0"
import {
  LogoutIcon,
  SupportIcon,
} from "@earnnest-e2-frontend/platform-ui/src/Icons"
import LoadingOverlay from "@earnnest-e2-frontend/platform-ui/src/LoadingOverlay/LoadingOverlay"
import SupportForm from "@earnnest-e2-frontend/platform-views/src/mantine/SupportForm"
import {
  DashboardLayout,
  Panel,
} from "@earnnest-e2-frontend/platform-ui/src/mantine"
import {
  RiArrowLeftRightFill,
  RiFileList3Fill,
  RiWebhookFill,
} from "react-icons/ri"
import {
  Redirect,
  Route,
  Switch,
  useLocation,
  useRouteMatch,
  useHistory,
  Link,
} from "react-router-dom"
import { Title, Image } from "@mantine/core"
import CallbackPage from "./pages/CallbackPage"
import ErrorPage from "./pages/ErrorPage"
import LoginPage from "./pages/LoginPage"
import LogoutPage from "./pages/LogoutPage"
import TransactionsScreen from "./pages/TransactionsScreen"
import WebhookAttemptsScreen from "./pages/WebhookAttemptsScreen"
import WebhooksScreen from "./pages/WebhooksScreen"
import TransactionPanel from "./pages/TransactionPanel"

export default function App() {
  const history = useHistory()
  const location = useLocation()
  const background = location.state?.background
  const { isLoading, isAuthenticated, logout } = useEarnnestAuth0()

  const routeMatch = useRouteMatch({ path: "/:integrationId" })
  const integrationId = routeMatch?.params.integrationId

  const integrationQuery = useIntegrationQuery({
    variables: {
      id: integrationId,
    },
    skip: !integrationId || !isAuthenticated,
  })

  if (isLoading || integrationQuery.loading) {
    return <LoadingOverlay />
  }

  if (integrationQuery.error) {
    return (
      <ErrorPage
        errorCode={integrationQuery.error ? "" : "404"}
        errorMessage={
          integrationQuery.error?.message || "Integration not found"
        }
      />
    )
  }

  if (isAuthenticated) {
    return (
      <Switch>
        <Route path="/:integrationId">
          <DashboardLayout
            mainNavItems={[
              {
                to: {
                  pathname: `/${integrationId}/transactions`,
                },
                icon: <RiArrowLeftRightFill width={16} height={16} />,
                title: "Transactions",
                header: (
                  <Title size="h2" order={1}>
                    Transactions
                  </Title>
                ),
              },
              {
                to: {
                  pathname: `/${integrationId}/webhooks`,
                },
                icon: <RiWebhookFill width={16} height={16} />,
                title: "Webhooks",
                header: (
                  <Title size="h2" order={1}>
                    Webhooks
                  </Title>
                ),
              },
              {
                to: {
                  pathname: `/${integrationId}/logs`,
                },
                icon: <RiFileList3Fill width={16} height={16} />,
                title: "Logs",
                header: (
                  <Title size="h2" order={1}>
                    Logs
                  </Title>
                ),
              },
            ]}
            subNavItems={[
              {
                to: {
                  pathname: `/${integrationId}/support`,
                  state: { background: location },
                },
                icon: <SupportIcon />,
                title: "Support",
              },
              {
                icon: <LogoutIcon />,
                title: "Logout",
                onClick: () => {
                  logout({
                    returnTo: `${
                      window.location.origin
                    }/logout?redirectTo=${encodeURIComponent(
                      window.location.pathname,
                    )}`,
                  })
                },
              },
            ]}>
            <Switch location={background || location}>
              <Redirect
                from="/:integrationId"
                exact
                to="/:integrationId/transactions"
              />
              <Route
                path="/:integrationId/transactions"
                component={TransactionsScreen}
              />
              <Route
                path="/:integrationId/webhooks"
                component={WebhooksScreen}
              />
              <Route
                path="/:integrationId/logs"
                component={WebhookAttemptsScreen}
              />
              <ErrorPage
                errorCode="404"
                errorMessage="This is not a valid url."
              />
            </Switch>
            <Route path="/:integrationId/transactions/:transactionTemplateId">
              {({ match }) => (
                <Panel
                  position="right"
                  opened={!!match}
                  onClose={() => {
                    if (background) {
                      history.push(background)
                    } else {
                      const searchParams = new URLSearchParams(location.search)
                      searchParams.delete("panelTab")
                      history.push({
                        pathname: `/${integrationId}/transactions`,
                        search: searchParams.toString(),
                      })
                    }
                  }}>
                  <TransactionPanel />
                </Panel>
              )}
            </Route>
            <Route path="/:integrationId/support">
              {({ match }) => (
                <Panel
                  position="right"
                  opened={!!match}
                  onClose={() => {
                    if (background) {
                      history.push(background)
                    } else {
                      history.push(`/${integrationId}/transactions`)
                    }
                  }}>
                  <SupportForm
                    viewerRole="admin"
                    extraParams={{
                      // TODO: replace these
                      Origin: "EPDashboard",
                      Subject: "T-NA-NA-EPDashboard",
                    }}
                    onSubmitSuccess={() => {
                      if (background) {
                        history.push(background)
                      } else {
                        history.push(`/${integrationId}/transactions`)
                      }
                    }}
                  />
                </Panel>
              )}
            </Route>
          </DashboardLayout>
        </Route>
        <Route path="*">
          <ErrorPage errorCode="404" errorMessage="This is not a valid url." />
        </Route>
      </Switch>
    )
  } else {
    return (
      <Switch>
        <Route path="/logout">
          <LogoutPage />
        </Route>
        <Route path="/callback">
          <CallbackPage />
        </Route>
        <Route path="/:integrationId">
          <LoginPage />
        </Route>
        <Route>
          <ErrorPage errorCode="404" errorMessage="This is not a valid url." />
        </Route>
      </Switch>
    )
  }
}
