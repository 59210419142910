import * as React from "react"
import Svg, { Path } from "react-native-svg"

function LogoutIcon(props) {
  return (
    <Svg width={16} height={13} viewBox="0 0 16 13" fill="none" {...props}>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.777 7.138l-5.332 5.599c-.477.5-1.301.15-1.301-.566v-3.2H4.825c-.422 0-.761-.356-.761-.8V4.973c0-.443.34-.8.761-.8h4.319v-3.2c0-.714.822-1.067 1.3-.566l5.333 5.598a.836.836 0 010 1.134zm-9.682 5.433v-1.333c0-.22-.173-.4-.381-.4H3.047c-.562 0-1.016-.476-1.016-1.067V3.373c0-.59.454-1.067 1.016-1.067h2.667c.21 0 .38-.18.38-.4V.572c0-.22-.17-.4-.38-.4H3.047C1.365.172 0 1.605 0 3.372v6.4c0 1.765 1.365 3.198 3.047 3.198h2.667c.21 0 .38-.179.38-.4z"
        fill="currentColor"
      />
    </Svg>
  )
}

export default LogoutIcon
