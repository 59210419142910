import {
  TransactionDirection,
  TransactionTemplateFragment,
  TransactionTemplateStatus,
} from "@earnnest-e2-frontend/platform-api/src/graphql"
import { Group, Text, Timeline } from "@mantine/core"
import moment from "moment"
import React from "react"
import { RiCheckLine, RiCloseLine } from "react-icons/ri"

export default function TransactionTimeline({
  transactionTemplate,
}: {
  transactionTemplate: TransactionTemplateFragment
}) {
  if (!transactionTemplate) {
    return null
  }

  const transaction = transactionTemplate.transactions?.[0]
  const paymentOccasion = transactionTemplate.paymentOccasion

  const direction = paymentOccasion?.direction

  const staticItems = [
    // TODO: Requested
    // TODO: Accepted
    {
      title: "Created",
      date: transactionTemplate?.createdAt || transactionTemplate?.authorizedAt,
      description: `Payment created`, // TODO: by name
      kind: "success",
    },
    direction === TransactionDirection.Outbound &&
    transactionTemplate.approvedAt &&
    transactionTemplate.approvedBy
      ? {
          title: "Approved",
          date: transactionTemplate.approvedAt,
          description: `Payment approved by ${transactionTemplate.approvedBy?.fullName}`,
          kind: "success",
        }
      : null,
    transactionTemplate.receiverEmail
      ? {
          title: "Accessed",
          date: transactionTemplate?.accessedAt,
          description: transactionTemplate?.accessedAt
            ? `${transactionTemplate.receiverEmail} accessed payment.`
            : "Waiting for receiver to view payment request.",
          kind: "success",
        }
      : null,
    {
      title: "Authorized",
      date: transactionTemplate?.authorizedAt,
      description: transactionTemplate?.authorizedAt
        ? `${transactionTemplate.authorizedBy?.fullName} authorized payment.`
        : "Waiting for payment authorization.",
      kind: "success",
    },
    direction === TransactionDirection.Inbound &&
    transactionTemplate.approvedAt &&
    transactionTemplate.approvedBy
      ? {
          title: "Approved",
          date: transactionTemplate.approvedAt,
          description: `Payment approved by ${transactionTemplate.approvedBy?.fullName}`,
          kind: "success",
        }
      : null,
    {
      title: "Initiated",
      date: transaction?.initiatedAt || transaction?.clearedAt,
      description:
        transaction?.initiatedAt || transaction?.clearedAt
          ? `Payment initiated from ${transaction?.originationFundingSource?.displayName}.`
          : "Waiting for payment to process out of sending account.",
      kind: "success",
    },
    {
      title: "Deposited",
      date: transaction?.depositedAt,
      description: transaction?.depositedAt
        ? "Payment has settled"
        : "Waiting for payment to settle", // TODO: into funding source name
      kind: "success",
    },
  ].filter((x) => !!x)

  const dynamicItems = [
    {
      title: "Rejected",
      date: transactionTemplate.rejectedAt || true,
      description: `Payment rejected by ${""}.`,
      include:
        transactionTemplate.status ===
        TransactionTemplateStatus.ApprovalRejected,
      kind: "failure",
    },
    {
      title: "Blocked",
      date: true,
      description: "Payment blocked.",
      include: transaction?.status === "BLOCKED",
      kind: "failure",
    },
    {
      title: "Canceled",
      date: transactionTemplate?.canceledAt,
      include: transaction?.status === "CANCELED",
      description: `Payment canceled by ${""}.`,
      kind: "failure",
    },
    {
      title: "Failed",
      date: true,
      include: transaction?.status === "FAILED",
      description: `Payment failed.`,
      kind: "failure",
    },
  ].filter(({ include }) => !!include)

  const timelineItems = dynamicItems.length
    ? [...staticItems.filter((x) => !!x.date), ...dynamicItems]
    : [...staticItems]

  const numCompleted =
    dynamicItems.length || transaction?.depositedAt
      ? timelineItems.length
      : staticItems.findIndex((x) => !x.date)

  return (
    <Timeline active={numCompleted}>
      {timelineItems.map((item, i) => (
        <Timeline.Item
          key={i}
          color={item.kind === "failure" ? "red" : "green"}
          bullet={
            item.date ? (
              item.kind === "failure" ? (
                <RiCloseLine />
              ) : (
                <RiCheckLine />
              )
            ) : null
          }
          title={
            <Group align="baseline" spacing="xs">
              <Text weight="bold">{item.title}</Text>
              {item.date ? (
                <Text size="sm" color="dimmed">
                  {moment(item.date).format("MMM D, h:mm A")}
                </Text>
              ) : null}
            </Group>
          }>
          <Text size="sm">{item.description || "Just a test"}</Text>
        </Timeline.Item>
      ))}
    </Timeline>
  )
}
